import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import { selectCurrencyFormat, selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import ModalViewWorkflowByPayroll from 'features/configuration/pages/SetupWorkflowType/components/ModalViewWorkflowByPayroll';
import {
  createPayroll,
  deletePayroll,
  getPayRunsGroup,
  updatePayroll,
} from 'features/payroll/payrollAction';
import { selectPayroll } from 'features/payroll/payrollSlice';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { getUser } from 'utils/auth';
import { removeAccents } from 'utils/text';
import { checkPermissionCurrentUser } from '../ManageRequest/functionForWorkFlow';
import { ProbationSalarayDetailModal } from './ProbationSalarayDetailModal';
import { ProbationSalarayConfirm } from './ProbationSalarayConfirm';
import { FILTER_PAYRUN_GROUP, MODE_PAYROLL, TYPE_PAYROLL } from 'constants/payroll';

export const ProbationSalaray = (props: any) => {
  const { monthYear, fetchListStatusTabPayrollAPI, isProbationSalaray } = props;
  const dispatch = useDispatch();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [mode, setMode] = useState<string>('');
  const [isPayroll, setIsPayroll] = useState<any>(null);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const { t } = useTranslation(['menu', 'action', 'employee', 'timesheet', 'payroll']);
  const history = useHistory();
  const payRunGroupData = useAppSelector(selectPayroll)?.payRunGroupData;
  const loadingPayRunGroupData = useAppSelector(selectPayroll)?.loadingPayRunGroupData;
  const [data, setData] = useState([]);
  const [idDetailsData, setIdDetailsData] = useState([]);
  const formatDate = useAppSelector(selectFormatDate);
  const currencyFormat = useAppSelector(selectCurrencyFormat);
  const [isButtonAddNew, setIsBottonAddNew] = useState(true);

  useEffect(() => {
    callApiPayRunsGroup();
  }, [monthYear, dispatch]);

  const callApiPayRunsGroup = () => {
    const params = {
      monthYear: monthYear ? moment(monthYear).format('YYYY-MM') : '',
    };
    dispatch(getPayRunsGroup(params));
  };

  const reformatData = (data: any) => {
    const checkIDView = payRunGroupData?.payrollDetails?.map((i: any) => i?.id) || [];
    const idDetails = checkIDView?.map((id: any) => parseInt(id, 10));
    setIdDetailsData(idDetails);
    return data?.payrollDetails
      ? data?.payrollDetails?.map((i: any) => ({ ...i, id: `${data?.id}_${i?.id}` }))
      : [];
  };
  useEffect(() => {
    if (payRunGroupData) {
      setIsBottonAddNew(false);
      setData(reformatData(payRunGroupData));
    } else {
      setIsBottonAddNew(true);
      setData([]);
      setIdDetailsData([]);
    }
  }, [payRunGroupData]);
  const [formView] = Form.useForm();

  const cutStringCharacter = (string: string) => {
    let arr = string?.split('_');
    return arr[1];
  };

  const MENU_CHILD = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      checkDisabled: (record: any) =>
        !checkPermissionCurrentUser(record?.workflowDetails, 'view', record?.status, getUser()?.id),
      handleClick: (value: any) => {
        history.push({
          pathname: `/payroll/view-employee/detail/${cutStringCharacter(value?.id)}`,
          state: FILTER_PAYRUN_GROUP.PROBATION,
        });
      },
    },
    {
      name: t('payroll:viewWorkflowByPayroll'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      checkDisabled: (record: any) =>
        !checkPermissionCurrentUser(record?.workflowDetails, 'view', record?.status, getUser()?.id),
      handleClick: (value: any) => {
        setCurrentItem(value);
        setMode(MODE_PAYROLL.VIEWWL);
      },
    },
    {
      name: t('payroll:editPayRun'),
      width: 100,
      minWidth: 100,
      checkDisabled: (record: any) =>
        !checkPermissionCurrentUser(record?.workflowDetails, 'edit', record?.status, getUser()?.id),
      icon: <EditOutlined style={{ color: '#F1592A' }} />,
      handleClick: (data: any) => {
        setCurrentItem(data);
        setIsPayroll(data);
        setMode(MODE_PAYROLL.UPDATE);
      },
    },
    {
      name: t('action:delete'),
      type: 'delete',
      checkDisabled: (record: any) =>
        !checkPermissionCurrentUser(
          record?.workflowDetails,
          'delete',
          record?.status,
          getUser()?.id,
        ),
      icon: <DeleteOutlined />,
      handleClick: (data: any) => {
        dispatch(
          deletePayroll({
            id: cutStringCharacter(data?.id),
            callBack: () => {
              callApiPayRunsGroup();
            },
          }),
        );
      },
    },
  ];

  const handleShowMenu = (record: any) => {
    return MENU_CHILD;
  };
  const COLUMNS: any = [
    {
      title: t('payroll:name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      minWidth: 100,
      ellipsis: true,
      align: 'left',
    },
    {
      title: t('payroll:payPeriod'),
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'left',
      width: 60,
      minWidth: 60,
      render: function (text: any, record: any, index: any) {
        return monthYear ? moment(monthYear).format('YYYY-MM') : '';
      },
    },
    {
      title: t('payroll:timeRange'),
      dataIndex: 'timeRange',
      key: 'timeRange',
      align: 'left',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return record?.startDate && record?.endDate
          ? `${moment(record?.startDate).format(formatDate)} - ${moment(record?.endDate).format(
              formatDate,
            )}`
          : '';
      },
    },
    {
      title: t('payroll:payDate'),
      dataIndex: 'payDate',
      key: 'payDate',
      align: 'left',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return record?.paidDate ? `${moment(record?.paidDate).format(formatDate)}` : '';
      },
    },
    {
      title: t('payroll:description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 100,
      minWidth: 100,
      ellipsis: true,
    },
    {
      title: t('payroll:status'),
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      width: 100,
      minWidth: 100,
    },
    {
      title: t('payroll:totalCompanyCost'),
      dataIndex: 'totalCompanyCost',
      key: 'totalCompanyCost',
      align: 'right',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? numeral(text).format(currencyFormat) : '';
      },
    },
    {
      title: t('payroll:totalNetPay'),
      dataIndex: 'totalReceiveSalary',
      key: 'totalReceiveSalary',
      align: 'right',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? numeral(text).format(currencyFormat) : '';
      },
    },
    {
      title: t('employee:employee_information_fields.action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 60,
      minWidth: 60,
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={handleShowMenu(record)} data={record} />;
      },
    },
  ];

  const createPayRoll = (form: FormInstance<any>, users: any, handleClose: any) => {
    form.validateFields().then((value: any) => {
      const body = {
        name: value?.name || '',
        description: value?.description || '',
        startDate: value?.timeRange[0] ? moment(value?.timeRange[0]).format('YYYY-MM-DD') : null,
        endDate: value?.timeRange[1] ? moment(value?.timeRange[1]).format('YYYY-MM-DD') : null,
        paidDate: value?.payDate ? moment(value?.payDate).format('YYYY-MM-DD') : null,
        payrollGroupId: isPayroll?.id,
        workFlowTypeId: value?.workflow || '',
        userIds: users?.map((item: any) => item?.id) || [],
        type: FILTER_PAYRUN_GROUP.PROBATION,
      };
      dispatch(
        createPayroll({
          body,
          callBack: () => {
            callApiPayRunsGroup();
            handleClose();
          },
        }),
      );
    });
  };

  const updatePayRoll = (form: FormInstance<any>, id: any, handleClose: any) => {
    form.validateFields().then((value: any) => {
      const body = {
        name: value?.name || '',
        description: value?.description || '',
        startDate: value?.timeRange[0] ? moment(value?.timeRange[0]).format('YYYY-MM-DD') : null,
        endDate: value?.timeRange[1] ? moment(value?.timeRange[1]).format('YYYY-MM-DD') : null,
        paidDate: value?.payDate ? moment(value?.payDate).format('YYYY-MM-DD') : null,
        workFlowTypeId: value?.workflow || '',
        type: TYPE_PAYROLL.TITLE,
        probationType: FILTER_PAYRUN_GROUP.PROBATION,
      };
      dispatch(
        updatePayroll({
          id: cutStringCharacter(id),
          body,
          callBack: () => {
            callApiPayRunsGroup();
            handleClose();
          },
        }),
      );
    });
  };

  const handleSearch = (value: string) => {
    const text = value && removeAccents(value.toLowerCase());
    if (text) {
      const temp = {
        ...data,
        payrollDetails: payRunGroupData?.payrollDetails?.filter((item: any) => {
          return item?.name && removeAccents(item?.name?.toLowerCase()).includes(text);
        }),
      };
      setData(reformatData(temp));
    } else {
      setData(reformatData(payRunGroupData));
    }
  };
  const addNewPayRunGroupData = () => {
    setMode(MODE_PAYROLL.ADD);
    setIsPayroll(payRunGroupData);
  };
  const addNewConfirm = () => {
    return isProbationSalaray ? null : (
      <CustomButton
        disabled={isButtonAddNew}
        onClick={() => {
          addNewPayRunGroupData();
        }}
      >
        <PlusOutlined /> {t('payroll:addNewPayRun')}
      </CustomButton>
    );
  };

  return (
    <Container>
      <ContainerBgWhite>
        <StyledWrapperFilters>
          <SearchContainer>
            <ITVSearch handleSearch={handleSearch} style={{ width: '210px' }} />
          </SearchContainer>

          <StyledWrapperActionBtn>
            <ProbationSalarayConfirm
              monthYear={monthYear}
              fetchListStatusTabPayrollAPI={fetchListStatusTabPayrollAPI}
              isProbationSalaray={isProbationSalaray}
              idDetailsData={idDetailsData}
            />
            {addNewConfirm()}
          </StyledWrapperActionBtn>
        </StyledWrapperFilters>
        <ITVTable
          loading={loadingPayRunGroupData}
          isRowSelect={false}
          columns={COLUMNS}
          data={data}
          width={1080}
          height={550}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </ContainerBgWhite>
      {isPayroll ? (
        <ProbationSalarayDetailModal
          mode={mode}
          setMode={setMode}
          currentItem={currentItem}
          isPayroll={isPayroll}
          setCurrentItem={setCurrentItem}
          setIsPayroll={setIsPayroll}
          monthYear={monthYear}
          callBack={(form: any, users: any, handleClose: any, id?: any) => {
            mode === MODE_PAYROLL.ADD
              ? createPayRoll(form, users, handleClose)
              : updatePayRoll(form, id, handleClose);
          }}
        />
      ) : null}

      {mode === MODE_PAYROLL.VIEWWL && (
        <ModalViewWorkflowByPayroll
          currentItem={currentItem}
          form={formView}
          mode={mode}
          setMode={setMode}
        />
      )}
    </Container>
  );
};

const StyledWrapperFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const StyledWrapperActionBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const SearchContainer = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;
