import debounce from 'lodash/debounce';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SearchIcon } from 'assets/icons';
import { DateRangePickerField, InputField, SelectField } from 'components/FormFields';
import { DATE_FORMAT, DATE_PAYLOAD_FORMAT } from 'constants/commons';
import { EmployeeTimesheetPayload } from 'types';
import { HeaderRemove } from 'features/masterData/Department/styled';
import { CustomIcon } from 'components/ITVSearch';
import IconExport from 'assets/icons/ic_export_gray.svg';
import timesheetApi from 'api/timesheetApi';
import { exportToCSV } from 'utils/common';
import moment from 'moment';

const ALL_OPTION_VALUE = '0';

interface SelectOption {
  label: string;
  value: string | number;
}

interface TimesheetByRangeFiltersProps {
  initialValues?: any;
  onSubmit?: (payload: EmployeeTimesheetPayload) => void;
}

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledWrapperRight = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TimesheetByRangeFilters = ({ initialValues, onSubmit }: TimesheetByRangeFiltersProps) => {
  const { t } = useTranslation(['timesheet', 'common', 'status', 'action']);
  const [subViewByOptions, setSubViewByOptions] = useState<Array<SelectOption>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { control, watch, setValue, handleSubmit } = useForm<any>({
    defaultValues: initialValues,
  });

  const checkTypeOptions = [
    { label: t('status:All'), value: 'all' },
    { label: t('Online'), value: 'online' },
    { label: t('Machine'), value: 'machine' },
    { label: t('Camera AI'), value: 'camera' },
    { label: t('Onsite'), value: 'onsite' },
    { label: t('HKVision'), value: 'hkvision' },
  ];

  const handleFiltersSubmit = (payload: EmployeeTimesheetPayload) => {
    onSubmit?.(payload);
  };
  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );

  const debounceSearchChange = debounce(handleSubmit(handleFiltersSubmit), 350);

  const handleDateRangeChange = () => {
    handleSubmit(handleFiltersSubmit)();
  };

  const handleExport = async () => {
    try {
      setIsLoading(true);
      const response = await timesheetApi.exportTrackingCheckinOut({
        typeCheckin: initialValues?.typeCheckin,
        fromDate: moment(initialValues?.dateRange?.from || new Date()).format(DATE_PAYLOAD_FORMAT),
        toDate: moment(initialValues?.dateRange?.to || new Date()).format(DATE_PAYLOAD_FORMAT),
      });
      setIsLoading(false);

      const exportFileName = `Tracking.xlsx`;
      exportToCSV(response, exportFileName);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleFiltersSubmit)}>
      <DateRangePickerField
        name="dateRange"
        control={control}
        format={DATE_FORMAT}
        allowClear
        onChange={handleDateRangeChange}
        disabledDate={(current) => {
          const today = moment();
          const thirtyOneDaysAgo = moment().subtract(31, 'days');
          return current && (current > today || current < thirtyOneDaysAgo);
        }}
      />

      <StyledWrapperRight>
        <InputField
          name="searchName"
          control={control}
          placeholder={t('action:search')}
          prefix={<SearchIcon />}
          onChange={debounceSearchChange}
        />

        <SelectField
          name="typeCheckin"
          control={control}
          options={checkTypeOptions}
          showSearch
          allowClear
          onChange={handleSubmit(handleFiltersSubmit)}
          style={{ width: 150 }}
        />
        <HeaderRemove onClick={handleExport} icon={IconExportComponents} loading={isLoading}>
          {t('action:export')}
        </HeaderRemove>
      </StyledWrapperRight>
    </StyledForm>
  );
};

export default TimesheetByRangeFilters;
