import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import timesheetApi from 'api/timesheetApi';
import { useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { DATE_PAYLOAD_FORMAT } from 'constants/commons';
import { path } from 'constants/path';
import { PERMISSION_TIMESHEET_MANAGE_EMPLOYEE } from 'constants/permissions';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectEmployee } from 'features/employee/employeeSlice';
import { DEFAULT_CURRENT_PAGE } from 'hrm-common/extensions/constant/personel';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { EmployeeTimesheetPayload, EmployeeTimesheetResponse } from 'types';
import { getUser } from 'utils/auth';
import TimesheetByRangeFilters from './TimesheetByRangeFilters';
import TimesheetByRangeList from './TimesheetByRangeList';

const StyledWrapperFilters = styled.div`
  position: relative;
`;

export function CheckinTracking() {
  const { t } = useTranslation(['employee', 'timesheet']);
  const history = useHistory();
  const location = useLocation();
  const searchParams = qs.parse(location.search);
  const selectRowKey = useAppSelector(selectEmployee).selectRowKey as string[];
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(DEFAULT_CURRENT_PAGE);
  const [employeeTimesheet, setEmployeeTimesheet] = useState<EmployeeTimesheetResponse | null>({
    result: [],
    totalResult: 0,
    totalWorkDay: 0,
    limit: 0,
    total: 0,
    offset: 0,
  });
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const menuStore = useAppSelector(selectConfiguration);
  const [titleHeader, setTitleHeader] = useState('');
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const filters = useMemo(
    () => ({
      fromDate: (searchParams.fromDate as string) || moment(new Date()).format(DATE_PAYLOAD_FORMAT),
      toDate: (searchParams.toDate as string) || moment(new Date()).format(DATE_PAYLOAD_FORMAT),
      searchName: (searchParams.searchName as string) || '',
      typeCheckin: (searchParams.typeCheckin as string) || 'all',
    }),
    [searchParams.fromDate, searchParams.searchName, searchParams.typeCheckin, searchParams.toDate],
  );

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const initialValues = {
    dateRange: {
      from: filters.fromDate ? new Date(filters.fromDate) : new Date(),
      to: filters.toDate ? new Date(filters.toDate) : new Date(),
    },
    searchName: filters.searchName,
    typeCheckin: filters.typeCheckin,
  };

  useEffect(() => {
    const fetchEmployeeTimesheetAPI = async () => {
      setEmployeeTimesheet(null);
      try {
        const params: any = {
          ...filters,
          offset: page,
          limit: limit,
        };

        if (
          !getUser()?.permissions?.includes(
            PERMISSION_TIMESHEET_MANAGE_EMPLOYEE.TIMESHEET_MANAGE_EMPLOYEE,
          )
        ) {
          params.managerId = getUser().employeeId;
        }
        if (isCheckedAll) {
          //@ts-ignore
          delete params.limit;
          //@ts-ignore
          delete params.offset;
        }
        setIsLoading(true);
        const response = await timesheetApi.getTrackingCheckinOut(params);
        setIsLoading(false);

        setEmployeeTimesheet(response?.data);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchEmployeeTimesheetAPI();
  }, [page, limit, filters, isCheckedAll]);

  const handleFiltersChange = ({ dateRange, ...restPayload }: EmployeeTimesheetPayload) => {
    const fromDate = dateRange?.from ? moment(dateRange.from).format(DATE_PAYLOAD_FORMAT) : '';
    const toDate = dateRange?.to ? moment(dateRange.to).format(DATE_PAYLOAD_FORMAT) : '';

    setPage(DEFAULT_CURRENT_PAGE);

    history.push({
      pathname: path.tracking,
      search: qs.stringify({
        ...filters,
        ...restPayload,
        fromDate,
        toDate,
      }),
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    if (pagination?.current === page) {
      setPage(DEFAULT_CURRENT_PAGE);

      history.push({
        pathname: path.employeeTimesheet,
        search: qs.stringify({
          ...filters,
        }),
      });
    }
  };

  return (
    <>
      <PageHeader title={titleHeader} />
      <ContainerBgWhite>
        <StyledWrapperFilters>
          <TimesheetByRangeFilters initialValues={initialValues} onSubmit={handleFiltersChange} />
        </StyledWrapperFilters>

        <TimesheetByRangeList
          employeeTimesheetList={employeeTimesheet?.result || []}
          totalResult={employeeTimesheet?.totalResult || 0}
          loading={isLoading}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          onTableChange={handleTableChange}
          isCheckedAll={isCheckedAll}
          setIsCheckedAll={setIsCheckedAll}
        />
      </ContainerBgWhite>
    </>
  );
}
