import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Alert, Calendar, ConfigProvider, Select } from 'antd';
import { dayInWeek, MONTH_NAMES } from 'constants/commons';
import moment, { Moment, updateLocale } from 'moment';
import 'moment/locale/en-gb';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { transMomentTimezone } from 'utils/date';

moment.locale('en-gb');
interface ITVCalendarProps {
  dateCellRender?: ((date: moment.Moment) => React.ReactNode) | undefined;
  currentUser: any;
  currentValue: moment.Moment;
  setCurrentValue: any;
  today?: string;
  clickable?: boolean;
}

const ITVCalendar = (props: ITVCalendarProps) => {
  const { t, i18n } = useTranslation(['common']);
  const {
    dateCellRender,
    currentValue,
    setCurrentValue,
    currentUser,
    today,
    clickable = false,
  } = props;

  const endDateUser: moment.Moment = currentUser?.endDate;
  const dateOff: moment.Moment = endDateUser && moment(endDateUser);
  const dateOn: moment.Moment = currentUser?.startDate && moment(currentUser?.startDate);
  const monthOff: number = dateOff?.month();
  const yearOff: number = dateOff?.year();

  const [listMonth, setListMonth] = useState<string[]>(MONTH_NAMES);
  const [disableMonth, setDisableMonth] = useState<boolean>(false);

  const handleChangeMonth = (value: Moment, nameMonth: string) => {
    value.month(MONTH_NAMES.indexOf(nameMonth));
    setCurrentValue && setCurrentValue(value);

    const setLocalDate: string = value as unknown as string;
    localStorage.setItem('current_date_choose', setLocalDate);
  };

  const handleSelectDate = (dateSelect: moment.Moment) => {
    return (
      setCurrentValue && setCurrentValue(transMomentTimezone(dateSelect.startOf('day').format()))
    );
  };

  // disable date in timesheet after day off
  const checkDisableDate = (dateOfMonth: moment.Moment): boolean => {
    if (dateOfMonth.endOf('date').valueOf() > dateOff?.endOf('date').valueOf()) {
      return true;
    }
    if (dateOfMonth.endOf('date').valueOf() < dateOn?.endOf('date').valueOf()) {
      return true;
    }
    return false;
  };

  const checkDisableMonth = (valueMonth: number, valueYear: number) => {
    // check disable next, previous month
    if (valueMonth === monthOff && valueYear === yearOff) {
      setDisableMonth(true);
    } else {
      setDisableMonth(false);
    }

    // Check disable select month
    if (valueYear === yearOff) {
      const listNewMonth = MONTH_NAMES.filter((month: string, index: number) => index <= monthOff);
      setListMonth(listNewMonth);
    } else {
      setListMonth(MONTH_NAMES);
    }
  };

  useEffect(() => {
    const cloneValue = currentValue.clone();
    const valueMonth: number = cloneValue.month();
    const valueYear: number = cloneValue.year();

    checkDisableMonth(valueMonth, valueYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthOff, yearOff, currentValue]);

  const handlePreviousMonth = (value: moment.Moment) => {
    const valueMonth: number = value.month();

    if (valueMonth > 0) {
      value.month(valueMonth - 1);
    } else {
      value.month(11);
      value.year(value.year() - 1);
    }

    setCurrentValue && setCurrentValue(value);
    // props.reDate && props.reDate(value);
    localStorage.setItem('current_date_choose', value as unknown as string);
  };

  const handleNextMonth = (value: moment.Moment) => {
    const valueMonth: number = value.month();

    if (valueMonth < 11) {
      value.month(valueMonth + 1);
    } else {
      value.month(0);
      value.year(value.year() + 1);
    }
    setCurrentValue && setCurrentValue(value);
    // props.reDate && props.reDate(value);
    localStorage.setItem('current_date_choose', value as unknown as string);
  };

  useEffect(() => {
    const currentDateChoose = localStorage.getItem('current_date_choose');
    if (currentDateChoose) {
      setCurrentValue && setCurrentValue(transMomentTimezone(currentDateChoose));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container today={today?.split('T')[0]} clickable={clickable}>
      <ConfigProvider
        locale={{
          //@ts-ignore
          lang: {
            local: moment.locale('en', {
              week: {
                dow: 1,
              },
              dayFormat: updateLocale('en', {
                weekdaysMin: dayInWeek(i18n.language ? i18n.language : 'en'),
              }),
            }),
          },
        }}
      >
        <Calendar
          value={transMomentTimezone(currentValue.format())}
          onSelect={handleSelectDate}
          disabledDate={(date: moment.Moment) => checkDisableDate(date)}
          dateFullCellRender={dateCellRender}
          mode="month"
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const newValue: moment.Moment = value.clone();
            return (
              <Header clickable={clickable}>
                <HeaderLeft>
                  <TextCurrentMonth>
                    {`${t(`month.${moment(currentValue).month()}.name`)}, ${moment(
                      currentValue,
                    ).year()}`}
                  </TextCurrentMonth>
                  <PreviousIcon
                    onClick={() => {
                      handlePreviousMonth(newValue);
                    }}
                  >
                    <LeftOutlined />
                  </PreviousIcon>

                  <NextIcon
                    disabled={disableMonth}
                    onClick={() => {
                      handleNextMonth(newValue);
                    }}
                  >
                    <RightOutlined />
                  </NextIcon>
                </HeaderLeft>

                <HeaderRight>
                  <CustomButton
                    aType="primary"
                    onClick={() => {
                      const newValue = transMomentTimezone();
                      setCurrentValue && setCurrentValue(newValue);
                      localStorage.setItem('current_date_choose', newValue.toString());
                    }}
                    style={{ marginRight: 10 }}
                  >
                    {t('today')}
                  </CustomButton>
                  <Select
                    defaultValue={listMonth[moment(currentValue).month()]}
                    style={{ width: 130 }}
                    onSelect={(nameMonth: string) => {
                      handleChangeMonth(newValue, nameMonth);
                    }}
                    value={listMonth[moment(currentValue).month()]}
                  >
                    {listMonth.map((value: string, index: number) => {
                      return (
                        <Select.Option key={value} value={value}>
                          {t(`month.${index}.name`)}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </HeaderRight>
              </Header>
            );
          }}
        />
      </ConfigProvider>
    </Container>
  );
};

export default ITVCalendar;

export const DateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

export const DateTag = styled(Alert)`
  padding: unset;
  border-radius: 5px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

interface HeaderTagProps {
  clickable: boolean;
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props: HeaderTagProps) =>
    props.clickable
      ? css`
          padding: 0 0 8px 0;
        `
      : css`
          padding: 15px 0;
        `}
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRight = styled.div`
  position: relative;
  right: -105px;
`;

export const TextCurrentMonth = styled.div`
  margin-right: 20px;
  font-weight: 600;
  font-size: 18px;
`;

export const TodayIcon = styled.button`
  margin: 0 10px;
  /* color: $label; */
  font-weight: 500;
`;

export const PreviousIcon = styled.button`
  /* color: $purple; */
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  background: transparent;
  border: unset;
`;

export const NextIcon = styled.button`
  /* color: $purple; */
  background: transparent;
  border: unset;
  font-size: 16px;
  cursor: pointer;
  ${(props) =>
    props.disabled === true &&
    css`
      color: rgba(0, 0, 0, 0.25);
    `}
`;

export const Container = styled.div<{ today?: string; clickable?: boolean }>`
  margin-top: 15px;
  margin-bottom: 15px;
  ${(props) =>
    props.clickable
      ? css`
          margin-top: 0;
          margin-bottom: 0;
        `
      : css`
          margin-top: 15px;
          margin-bottom: 15px;
        `}
  .ant-picker-calendar {
    background-color: transparent;
    .ant-picker-panel {
      .ant-picker-calendar-date-content {
        height: 120px;
        /* overflow-y: unset; */
      }
      .ant-picker-body {
        padding: 0;
        .ant-picker-content {
          thead {
            background-color: var(--cl_gray050);
            tr {
              th {
                border: 1px solid lightgrey;
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }
          }
          tbody > tr > td[title='${(props) => props.today}'] > div {
            /* background: #e6f7ff; */
            box-shadow: 0px 0px 3px 1px var(--cl_primary) inset;
          }
          .ant-picker-cell-selected > div {
            /* background: #e6f7ff; */
            box-shadow: 0px 0px 3px 1px var(--cl_info400) inset;
          }
        }
      }
      .ant-picker-cell {
        border: 1px solid lightgrey;
        &-today {
          // background: #e6f7ff;
          // border-top: 3px solid;
          // border-top-color: var(--cl_primary);
        }

        div[data-is-holiday='true'] {
          border: 1px solid orange;
          border-left: 0;
        }

        &:first-child {
          div[data-is-holiday='true'] {
            border: 1px solid orange;
          }
        }
      }
      .ant-picker-calendar-date {
        border-top: unset;
        &-value {
          font-weight: bold;
          text-align: left;
        }
        &-today {
          background: unset;
          border-color: var(--cl_primary);
          .ant-picker-calendar-date-value {
            color: var(--cl_primary);
            &:hover {
              color: var(--cl_primary);
            }
            &:focus {
              color: var(--cl_primary);
            }
          }
        }
      }
      .ant-picker-cell-selected {
        /* background: var(--cl_gray300); */
        .ant-picker-calendar-date {
          background: unset;
        }
        .ant-picker-calendar-date-value {
          color: var(--cl_primary);
          &:hover {
            color: var(--cl_primary);
          }
          &:focus {
            color: var(--cl_primary);
          }
        }
      }
    }
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
`;
