import { Modal } from 'antd';
import timesheetApi from 'api/timesheetApi';
import { useAppSelector } from 'app/hooks';
import ITVCalendar from 'components/ITVCalendar';
import { selectEmployee } from 'features/employee/employeeSlice';
import TimesheetFooter from 'features/timesheet/components/TimesheetFooter';
import TotalView from 'features/timesheet/components/TotalView';
import { TotalHoursPerWeek, ViewListType } from 'features/timesheet/interfaces';
import { TimeSheetApprovalStatus } from 'hrm-common/extensions/enums/personel';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { formatDate } from 'utils/date';
import CalendaTitle from './Calenda_title';
import {
  getTimesheetByUser,
  selectCurrentMonth,
  selectTimesheet,
  setCurrentMonth,
} from '../pages/timesheetSlice';

interface Props {
  onCancel: () => void;
  currentEmployeeId: any;
  isOpen: boolean;
}

const monthFormat = 'YYYY-MM';
const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';

const EmployeeTimeSheetModal = (props: Props) => {
  const { onCancel, currentEmployeeId, isOpen } = props;
  const dispatch = useDispatch();

  const stateEmployee = useAppSelector(selectEmployee);
  const currentMonth: string = useAppSelector(selectCurrentMonth);
  const timesheet = useAppSelector(selectTimesheet);

  const [calendarData, setCalendarData] = useState(timesheet?.calendarData);
  const [, setDataTotal] = useState(['1', '2', '3', '4', '5', '6']);
  const [datatype] = useState();
  const [dayOfmonth, setDayOfMonth] = useState();
  const currentSelectedMonth = moment(currentMonth).month();

  const statusRequest = calendarData?.statusRequest;
  const workTime = calendarData?.workTime;
  const daydate = calendarData.date;
  const isConfirmed = calendarData.conFirmStatus !== TimeSheetApprovalStatus.DEFAULT;
  const calendarWorkshifts = calendarData?.workshifts;
  const totalHoursPerWeek: TotalHoursPerWeek[] = Object.entries(
    calendarData.totalHoursPerWeek
      ? calendarData.totalHoursPerWeek
      : {
          1: { totalHoursWork: 0, totalHoursNoWork: 0, totalHoursOt: 0 },
          2: { totalHoursWork: 0, totalHoursNoWork: 0, totalHoursOt: 0 },
          3: { totalHoursWork: 0, totalHoursNoWork: 0, totalHoursOt: 0 },
          4: { totalHoursWork: 0, totalHoursNoWork: 0, totalHoursOt: 0 },
          5: { totalHoursWork: 0, totalHoursNoWork: 0, totalHoursOt: 0 },
          6: { totalHoursWork: 0, totalHoursNoWork: 0, totalHoursOt: 0 },
        },
  )?.map((o: any) => o[1]);

  const currentUser = { ...stateEmployee?.employeeDetail };

  useEffect(() => {
    dispatch(setCurrentMonth(moment().format()));
  }, [dispatch]);

  useEffect(() => {
    if (timesheet.calendarData) {
      setCalendarData(timesheet.calendarData);
    } else {
      setCalendarData({ ...calendarData, workTime: [], workshifts: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheet.calendarData]);

  useEffect(() => {
    refreshData();
    getDayOfMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedMonth, currentEmployeeId]);

  const getDayOfMonth = async () => {
    const bodydata = {
      monthYear: formatDate(moment(currentMonth), monthFormat),
    };
    try {
      const respons = await timesheetApi.apiDayOfMonth(bodydata);
      // @ts-ignore
      if (respons.statusCode === 200) {
        setDayOfMonth(respons.data);
      }
    } catch (e) {
      // console.log('error');
    }
  };

  const refreshData = () => {
    dispatch(
      getTimesheetByUser({
        monthYear: formatDate(moment(currentMonth), monthFormat),
        userId: currentEmployeeId,
      }),
    );
    return getTotalHours();
  };

  const getTotalHours = async () => {
    try {
      const params = {
        monthYear: formatDate(moment(currentMonth), monthFormat),
        userId: currentEmployeeId,
      };
      const respons = await timesheetApi.getTimesheetByUser(params);

      // @ts-ignore
      if (respons.statusCode === 200) {
        const object = respons.data.totalHoursPerWeek;
        const objectValues: any = [];
        Object.keys(object).forEach((objectKey) => {
          objectValues.push({
            id: objectKey,
            // @ts-ignore
            totalHoursWork: object[objectKey].totalHoursWork,
            // @ts-ignore
            totalHoursNoWork: object[objectKey].totalHoursNoWork,
            // @ts-ignore
            totalHoursOt: object[objectKey].totalHoursOt,
          });
        });
        if (objectValues.length <= 5) {
          objectValues.push({
            id: '11',
            totalHoursWork: 0,
            totalHoursNoWork: 0,
            totalHoursOt: 0,
          });
        }
        setDataTotal(objectValues);
      } else {
      }
    } catch (e) {}
  };

  const dateCellRender = (value: any) => {
    let currentDate: string = formatDate(value, dateFormat);
    let is_value: string = formatDate(value, 'DD');

    let currentWorkTime = workTime?.find(
      (v: any) => formatDate(v.currentDay, dateFormat) === currentDate,
    );

    let requestOverTimes = null,
      requestAdjustTime = null,
      requestLeave = null,
      requestLeaves = null,
      requestOverTime = null,
      checkoutWorkTimeFinal = null,
      checkinWorkTimeFinal = null;
    if (currentWorkTime) {
      requestAdjustTime = currentWorkTime.requestAdjustTime;
      checkoutWorkTimeFinal = requestAdjustTime
        ? formatDate(requestAdjustTime.endTime, timeFormat)
        : formatDate(currentWorkTime.checkoutTime, timeFormat);
      checkinWorkTimeFinal = requestAdjustTime
        ? formatDate(requestAdjustTime.startTime, timeFormat)
        : formatDate(currentWorkTime.checkinTime, timeFormat);
      requestLeave = currentWorkTime.requestLeave;
      requestLeaves = currentWorkTime.requestLeaves;
      requestOverTime = currentWorkTime.requestOverTime;
      requestOverTimes = currentWorkTime.requestOverTimes;
    }

    return (
      <CalendaTitle
        is_value={is_value}
        value={value}
        requestOverTime={requestOverTime}
        requestLeave={requestLeave}
        requestLeaves={requestLeaves}
        currentWorkTime={currentWorkTime}
        requestOverTimes={requestOverTimes}
        dayDate={daydate}
        refreshData={refreshData}
        requestAdjustTime={requestAdjustTime}
        timeFormat={timeFormat}
        userId={currentEmployeeId}
        checkoutTime={checkoutWorkTimeFinal}
        checkinTime={checkinWorkTimeFinal}
        calendarValue={moment(currentMonth)}
        datatype={datatype}
        viewList={ViewListType.MANAGE_VIEW}
        isConfirmed={isConfirmed}
        dayOfmonth={dayOfmonth}
        workDaysWithManager={calendarData.workDaysWithManager}
        typeCheckInOut={currentWorkTime?.type}
        currentUser={currentUser}
        calendarWorkshifts={calendarWorkshifts}
        isPopUpTimesheet={true}
      />
    );
  };

  const addCurrentMonth = useCallback(
    (currentMonth: Moment) => {
      dispatch(setCurrentMonth(currentMonth.format()));
    },
    [dispatch],
  );

  return (
    <ModalStyle
      open={isOpen}
      title={<b>Timesheet Detail of {currentUser.fullName}</b>}
      centered
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      width={1050}
    >
      <div
        style={{
          display: 'flex',
          overflow: 'auto',
        }}
      >
        <ITVCalendar
          currentUser={currentUser}
          dateCellRender={dateCellRender}
          currentValue={moment(currentMonth)}
          setCurrentValue={addCurrentMonth}
          today={daydate}
          clickable={true}
        />
        <TotalView dataTotal={totalHoursPerWeek} clickable={true} />
      </div>
      <TimesheetFooter
        approved={statusRequest?.approved}
        pending={statusRequest?.pending}
        rejected={statusRequest?.rejected}
        clickable={true}
      />
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  .ant-modal-centered .ant-modal {
    margin: 100px 0 !important;
  }
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-header {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 8px;
  }
`;
export default EmployeeTimeSheetModal;
