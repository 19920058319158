import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  getHistoryPayRuns,
  getPayrollDetailHistory,
  getPayrollGroupUser,
  getPayrollUser,
  getPayRuns,
  getPayRunsGroup,
  getPayslipByYear,
  getPaySlipDetail,
} from './payrollAction';

const initialState: any = {
  error: null,
  payRunData: {},
  payRunGroupData: {},
  loadingPayRunData: false,
  loadingPayRunGroupData: false,
  payRunHistoryData: {},
  loadingPayRunHistoryData: false,
  payrollGroupUser: {},
  loadingPayrollGroupUser: false,
  payrollUser: {},
  payrollDetailHistory: [],
  loadingPayrollDetailHistory: false,
  payslipData: [],
  payslipDetail: null,
  dataPayrollCostByDesignation: [],
};

const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.error = action.payload;
    },
    setDataPayrollCostByDesignationSlice(state, action) {
      state.dataPayrollCostByDesignation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayRuns.pending, (state, action: any) => {
        state.loadingPayRunData = true;
        state.error = null;
      })
      .addCase(getPayRuns.fulfilled, (state, action) => {
        state.loadingPayRunData = false;
        state.payRunData = action.payload.data;
      })
      .addCase(getPayRuns.rejected, (state, action: any) => {
        state.loadingPayRunData = false;
        state.error = action.payload;
        state.payRunData = null;
      })
      .addCase(getPayRunsGroup.pending, (state, action: any) => {
        state.loadingPayRunGroupData = true;
        state.error = null;
      })
      .addCase(getPayRunsGroup.fulfilled, (state, action) => {
        state.loadingPayRunGroupData = false;
        state.payRunGroupData = action.payload.data;
      })
      .addCase(getPayRunsGroup.rejected, (state, action: any) => {
        state.loadingPayRunGroupData = false;
        state.error = action.payload;
        state.payRunGroupData = null;
      })
      .addCase(getHistoryPayRuns.pending, (state, action: any) => {
        state.loadingPayRunHistoryData = true;
        state.error = null;
      })
      .addCase(getHistoryPayRuns.fulfilled, (state, action) => {
        state.loadingPayRunHistoryData = false;
        state.payRunHistoryData = action.payload.data;
      })
      .addCase(getHistoryPayRuns.rejected, (state, action: any) => {
        state.loadingPayRunHistoryData = false;
        state.error = action.payload;
        state.payRunHistoryData = null;
      })
      .addCase(getPayrollGroupUser.pending, (state, action: any) => {
        state.loadingPayrollGroupUser = true;
        state.error = null;
      })
      .addCase(getPayrollGroupUser.fulfilled, (state, action) => {
        state.loadingPayrollGroupUser = false;
        state.payrollGroupUser = action.payload.data;
      })
      .addCase(getPayrollGroupUser.rejected, (state, action: any) => {
        state.loadingPayrollGroupUser = false;
        state.error = action.payload;
        state.payrollGroupUser = null;
      })
      .addCase(getPayrollUser.pending, (state, action: any) => {
        state.payrollUser = null;
        state.error = null;
      })
      .addCase(getPayrollUser.fulfilled, (state, action) => {
        state.payrollUser = action.payload.data;
      })
      .addCase(getPayrollUser.rejected, (state, action: any) => {
        state.error = action.payload;
        state.payrollUser = null;
      })
      .addCase(getPayrollDetailHistory.pending, (state, action: any) => {
        state.error = null;
        state.loadingPayrollDetailHistory = true;
      })
      .addCase(getPayrollDetailHistory.fulfilled, (state, action) => {
        state.payrollDetailHistory = action.payload.data;
        state.loadingPayrollDetailHistory = false;
      })
      .addCase(getPayrollDetailHistory.rejected, (state, action: any) => {
        state.error = action.payload;
        state.payrollDetailHistory = null;
        state.loadingPayrollDetailHistory = false;
      })
      .addCase(getPayslipByYear.pending, (state, action: any) => {
        state.loadingPayslipData = true;
        state.error = null;
      })
      .addCase(getPayslipByYear.fulfilled, (state, action) => {
        state.loadingPayslipData = false;
        state.payslipData = action.payload?.data;
      })
      .addCase(getPayslipByYear.rejected, (state, action: any) => {
        state.loadingPayslipData = false;
        state.error = action.payload;
        state.payslipData = null;
      })
      .addCase(getPaySlipDetail.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getPaySlipDetail.fulfilled, (state, action) => {
        state.payslipDetail = action.payload?.data;
      })
      .addCase(getPaySlipDetail.rejected, (state, action: any) => {
        state.error = action.payload;
        state.payslipDetail = null;
      });
  },
});

export const { reducer, actions } = payrollSlice;
export const { setDataPayrollCostByDesignationSlice } = payrollSlice.actions;
export const selectPayroll = (state: RootState) => state.payroll;

export default payrollSlice.reducer;
