import { useTranslation } from 'react-i18next';

interface Props {
  dataTotal: any;
  clickable?: boolean;
}

const TotalView = (props: Props) => {
  const { dataTotal, clickable = false } = props;
  const { t } = useTranslation(['timesheet']);
  return (
    <div
      style={
        clickable
          ? { width: 105, background: '#F9FAFB', marginTop: 40 }
          : { width: 105, background: '#F9FAFB', marginTop: 77, marginBottom: 16 }
      }
    >
      <div
        style={{
          height: 39,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#E5E7EB',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {t('Total')} ({t('hrs')})
      </div>
      {dataTotal &&
        dataTotal?.map((item: any, index: number) => {
          let numberHeight = 0;

          switch (index) {
            case 1:
              numberHeight = 111;
              break;
            case 2:
              numberHeight = 111;
              break;
            case 3:
              numberHeight = 111;
              break;
            case 4:
              numberHeight = 111.5;
              break;

            default:
              numberHeight = 111.3;
          }

          return (
            <div
              style={{
                width: 105,
                height: numberHeight,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#E5E7EB',
              }}
              key={index}
            >
              <div
                style={{
                  width: '86%',
                  borderRadius: 6,
                  marginRight: '8%',
                  marginLeft: '8%',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '25%',
                }}
              >
                <div
                  style={{
                    height: 16,
                    fontSize: 12,
                    justifyContent: 'center',
                    fontFamily: 'Roboto',
                  }}
                >
                  {item.totalHoursWork || '0.0'}
                </div>
              </div>
              <div
                style={{
                  width: '86%',
                  borderRadius: 6,
                  marginRight: '8%',
                  marginLeft: '8%',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    fontSize: 12,
                    justifyContent: 'center',
                    color: '#99ccff',
                    fontFamily: 'Roboto',
                  }}
                >
                  {item.totalHoursNoWork || '0.0'}
                </div>
              </div>
              <div
                style={{
                  width: '86%',
                  borderRadius: 6,
                  marginRight: '8%',
                  marginLeft: '8%',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    height: 16,
                    fontSize: 12,
                    justifyContent: 'center',
                    color: '#EF4444',
                    fontFamily: 'Roboto',
                  }}
                >
                  {item.totalHoursOt}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TotalView;
