import { Modal, Tabs } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Summary from './components/summary/Summary';
import Hirring from './components/hirring/Hirring';
import Notes from './components/notes/Notes';
import Candidates from './components/candidates/Candidates';
import axiosClient from 'api/axiosClient';
import CustomButton from 'styles/buttonStyled';
import {
  createJobTemplate,
  updateJob,
  updateJobStatus,
} from 'features/recruitment/recruitmentAction';
import ModalConfirm from './ModalConfirm';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import { useHistory } from 'react-router-dom';
import ButtonBack from 'components/ButtonBack';
import { JobStatus, PermissionUser, isInterviewer } from '../components/types';
import { getUser } from 'utils/auth';
import InterviewQuestion from './components/questions/InterviewQuestion';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import ModalFormReason from 'features/timesheet/components/ModalFormReason';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PermissionType } from 'hrm-common/extensions/enums/personel';

const JobDetail = () => {
  const { t } = useTranslation(['recruitment', 'action', 'timesheet']);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location = useLocation();
  const stateURL = location.state;
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [data, setData] = useState<any>(null);
  const [oriData, setOriData] = useState<any>(null);
  const [tab, setTab] = useState<'summary' | 'hirring' | 'candidates' | 'notes'>('summary');
  const [visible, setVisible] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [itemActionSelected, setItemActionSelected] = useState({ type: '' });
  const listJobTypesData = useAppSelector(selectRecruitmentData)?.listJobTypes?.result;

  const fetchDetailJob = async () => {
    const response = await axiosClient.get(
      isInterviewer()
        ? `/recruitment/job/interviewer/detail/${id}`
        : `/recruitment/job/detail/${id}`,
    );
    if (isInterviewer()) {
      setData({
        ...response?.data,
        jobTemplate: {
          requirements: response?.data?.jobRequirements,
        },
      });
      setOriData({
        ...response?.data,
        jobTemplate: {
          requirements: response?.data?.jobRequirements,
        },
      });
    } else {
      setData({
        ...response?.data,
        jobTemplate: {
          ...response?.data?.jobTemplate,
          jobType: response?.data?.jobTemplate?.jobType?.id,
          department: response?.data?.jobTemplate?.department?.projectCode,
          location: response?.data?.jobTemplate?.location?.id,
        },
      });
      setOriData({
        ...response?.data,
        jobTemplate: {
          ...response?.data?.jobTemplate,
          jobType: response?.data?.jobTemplate?.jobType?.id,
          department: response?.data?.jobTemplate?.department?.projectCode,
          location: response?.data?.jobTemplate?.location?.id,
        },
      });
    }
  };

  useEffect(() => {
    if (id === 'draft' && stateURL) {
      const newData = {
        jobTemplate: {
          ...stateURL?.jobTemplates,
          jobType: stateURL?.jobTemplates?.jobType?.id,
          location: stateURL?.jobTemplates?.locationId || stateURL?.jobTemplates?.location?.id,
          department:
            stateURL?.jobTemplates?.departmentId || stateURL?.jobTemplates?.department?.projectCode,
        },
        hiringProcess: stateURL?.jobHirringProcess?.hiringProcessTemplate?.process,
        openDate: stateURL?.openDate,
        closeDate: stateURL?.closeDate,
      };
      setData(newData);
      setOriData(newData);
    } else {
      fetchDetailJob();
    }
  }, [id, stateURL]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const handleChange = (formData: any) => {
    setData(formData);
  };

  const tabs = [
    {
      tabName: t('recruitment:summary'),
      key: 'summary',
      component: <Summary data={data} handleChange={(value: any) => handleChange(value)} />,
    },
    {
      tabName: t('recruitment:hiring_process'),
      key: 'hirring',
      component: <Hirring data={data} handleChange={(value: any) => handleChange(value)} />,
    },
    {
      tabName: t('recruitment:candidates'),
      key: 'candidates',
      component: (
        <Candidates
          reload={fetchDetailJob}
          data={data}
          handleChange={(value: any) => handleChange(value)}
        />
      ),
    },
    {
      tabName: t('recruitment:interview_question'),
      key: 'interview',
      component: <InterviewQuestion data={data} />,
    },
    {
      tabName: t('recruitment:notes'),
      key: 'notes',
      component: <Notes data={data} handleChange={(value: any) => handleChange(value)} />,
    },
  ];

  const closeCreateUpdate = () => {
    // history.replace('/recruitment/jobs');
    setVisible(false);
  };
  const handleSave = (value?: string, status?: string) => {
    const dataToUpdate = { ...data, ...data?.jobTemplate };
    const { jobTemplate, ...rest } = dataToUpdate;
    const jobType = listJobTypesData?.find((i: any) => i?.id === rest?.jobType);
    if (JSON.stringify(data) === JSON.stringify(oriData) && id !== 'draft') {
      return notificationToast(
        Notification.Type.Warning,
        'Nothing change',
        Notification.Duration._3s,
      );
    }
    if (id === 'draft' && stateURL) {
      dispatch(
        createJobTemplate({
          body: {
            ...rest,
            departmentId: rest?.department,
            locationId: rest?.location,
            isDefault: value ? true : false,
            name: value,
            jobTypeId: jobType?.id,
            headcount: +rest?.headcount || undefined,
            requirements: rest?.requirements || '',
            status: status ? status : rest?.status,
            maximumSalary: rest?.maximumSalary || undefined,
            minimumSalary: rest?.minimumSalary || undefined,
          },
          callBack: () => {
            closeCreateUpdate();
            history.goBack();
          },
        }),
      );
    } else {
      dispatch(
        updateJob({
          id,
          body: {
            ...rest,
            departmentId: rest?.department,
            locationId: rest?.location,
            jobTypeId: jobType?.id,
            headcount: +rest?.headcount || undefined,
            status: status ? status : rest?.status,
            maximumSalary: rest?.maximumSalary || undefined,
            minimumSalary: rest?.minimumSalary || undefined,
          },
          callBack: () => {
            closeCreateUpdate();
            history.goBack();
          },
        }),
      );
    }
  };

  const isFormValid = () => {
    return (
      data?.jobCode &&
      data?.jobTemplate?.location &&
      data?.jobTemplate?.department &&
      // data?.jobTemplate?.minimumSalary &&
      // data?.jobTemplate?.maximumSalary &&
      data?.jobTemplate?.positionName &&
      data?.jobTemplate?.jobType
    );
  };

  const handleActionSave = (values: any) => {
    dispatch(
      updateJobStatus({
        id,
        body: {
          status:
            itemActionSelected?.type === JobStatus.APPROVED
              ? JobStatus.APPROVED
              : JobStatus.REJECTED,
          reason: values?.reason || '',
        },
        callBack: () => {
          setIsOpenModel(false);
          fetchDetailJob();
        },
      }),
    );
  };

  const getMenuForRecord = (type: 'Approve' | 'Reject') => {
    if (!isInterviewer() && getUser()?.permissions?.includes(PermissionUser.RECRUIT_JOB_APPROVE)) {
      if (oriData?.status === JobStatus.WAITING_FOR_APPROVAL) {
        if (type === 'Approve') return true;
      }

      if (oriData?.status === JobStatus.WAITING_FOR_APPROVAL || oriData?.status === JobStatus.NEW) {
        if (type === 'Reject') return true;
      }
    }

    return false;
  };

  return (
    <>
      <ButtonBack handleBack={() => history.replace('/recruitment/jobs')} />
      <PageHeaderWrapper>
        <PageHeader title={data?.jobTemplate?.positionName} />
        <span style={{ fontSize: 16 }}>
          {data?.jobRequestStatus ? `(${data?.jobRequestStatus})` : ''}
        </span>
      </PageHeaderWrapper>
      <Tabs activeKey={tab} onChange={(value: any) => setTab(value)}>
        {(isInterviewer()
          ? tabs.filter(
              (tab) => tab.key === 'candidates' || tab.key === 'summary' || tab.key === 'interview',
            )
          : tabs.filter((tab) => tab.key !== 'interview')
        ).map(({ tabName, key, component }) => (
          <Tabs.TabPane tab={tabName} key={key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
      {tab !== 'candidates' &&
        !(data?.status === JobStatus.APPROVED && tab !== 'notes') &&
        !isInterviewer() && (
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: 8, marginBottom: '1rem' }}
          >
            <CustomButton
              aType="text"
              onClick={() => {
                setData(oriData);
                closeCreateUpdate();
              }}
            >
              {t('action:cancel')}
            </CustomButton>
            {(tab === 'notes' ||
              !(
                (data?.status === JobStatus.APPROVED)
                // ||
                // data?.status === JobStatus.WAITING_FOR_APPROVAL
              )) && (
              <>
                <CustomButton
                  onClick={() => {
                    if (id === 'draft' && !stateURL?.isTemplate) {
                      setVisible(true);
                    } else handleSave();
                  }}
                  disabled={!isFormValid()}
                >
                  {t('action:save')}
                </CustomButton>
                {tab === 'summary' &&
                  !getUser()?.permissions?.includes(PermissionType.RECRUIT_JOB_APPROVE) && (
                    <CustomButton
                      onClick={() => {
                        handleSave(undefined, JobStatus.WAITING_FOR_APPROVAL);
                      }}
                      disabled={!isFormValid()}
                    >
                      {t('recruitment:send_for_approval')}
                    </CustomButton>
                  )}
                {tab === 'summary' && (
                  <>
                    {getMenuForRecord('Approve') && (
                      <CustomButton
                        onClick={() => {
                          setIsOpenModel(true);
                          setItemActionSelected({ type: JobStatus.APPROVED });
                        }}
                        disabled={!isFormValid()}
                      >
                        {t('timesheet:approve')}
                      </CustomButton>
                    )}
                    {getMenuForRecord('Reject') && (
                      <CustomButton
                        onClick={() => {
                          setIsOpenModel(true);
                          setItemActionSelected({ type: JobStatus.REJECTED });
                        }}
                        disabled={!isFormValid()}
                      >
                        {t('timesheet:Reject')}
                      </CustomButton>
                    )}
                  </>
                )}
              </>
            )}
            <ModalConfirm
              visible={visible}
              setVisible={setVisible}
              callBack={(value: any) => {
                if (value) {
                  handleSave(value);
                } else {
                  handleSave();
                }
              }}
            />
            <ModalFormReason
              title={t('recruitment:notes')}
              isModalReason={isOpenModel}
              setIsModalReason={setIsOpenModel}
              handleFinish={handleActionSave}
            />
          </div>
        )}
    </>
  );
};

export default JobDetail;

const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
