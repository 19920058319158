import { Modal, Space, Table, Tabs } from 'antd';
import { useAppSelector } from 'app/hooks';
import ButtonBack from 'components/ButtonBack';
import PageHeader from 'components/PageHeader';
import { getPayrollGroupUser } from 'features/payroll/payrollAction';
import { selectPayroll } from 'features/payroll/payrollSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ManageRequestTab from './ManageRequestTab';
import OverallHistory from './OverallHistory';
import ViewAddMember from './components/ViewAddMember';
import { selectEmployee } from 'features/employee/employeeSlice';

const { TabPane } = Tabs;

const ManageRequest = () => {
  const { id }: any = useParams();
  const { t } = useTranslation(['payroll']);
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState<any>({});
  const [showData, setShowData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [nameKey, setNameKey] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sort, setSort] = useState<'ASC' | 'DESC' | ''>('');
  const payrollGroupUser = useAppSelector(selectPayroll)?.payrollGroupUser;
  const isCheckAll: boolean = useAppSelector(selectEmployee).isCheckAll;
  const location = useLocation();
  const typeListProbation = location.state;

  const callApi = () => {
    const params = {
      id,
      limit: sizePage,
      offset: currentPage,
      nameKey,
      sortType: sort,
      sortBy,
    };
    dispatch(getPayrollGroupUser(params));
  };
  useEffect(() => {
    callApi();
  }, [dispatch, id, sizePage, currentPage, nameKey, sort, sortBy]);

  useEffect(() => {
    if (isCheckAll) {
      const params = {
        id,
        nameKey,
      };
      dispatch(getPayrollGroupUser(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckAll]);

  useEffect(() => {
    if (payrollGroupUser?.payrollDetails) setData(payrollGroupUser);
  }, [payrollGroupUser]);

  return (
    <>
      <ButtonBack handleBack={() => history.goBack()} />
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <PageHeader
              title={`${data?.payrollDetails?.name || ''} ${
                data?.payrollDetails?.status ? `(${data?.payrollDetails?.status})` : ''
              }`}
            />
          </Space>
        </div>
      </div>

      <Tabs destroyInactiveTabPane defaultActiveKey="1">
        <TabPane tab={t('payroll:manage_request')} key="1">
          <ManageRequestTab
            showData={showData}
            sizePage={sizePage}
            setSizePage={setSizePage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setSort={setSort}
            setSortBy={setSortBy}
            setSearch={setNameKey}
            callApi={callApi}
            typeListProbation={typeListProbation}
          />
        </TabPane>
        <TabPane tab={t('payroll:history')} key="2">
          <OverallHistory />
        </TabPane>
      </Tabs>

      <Modal
        visible={showModal}
        maskClosable={false}
        onCancel={() => setShowModal(false)}
        okText="Save"
        width={'fit-content'}
        onOk={() => {
          setShowModal(false);
          setShowData(true);
        }}
      >
        <ViewAddMember />
      </Modal>
    </>
  );
};

export default ManageRequest;
export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export const Cell = styled(Table.Summary.Cell)`
  .ant-table-cell {
    background: red !important;
    width: 100% !important;
  }
`;
