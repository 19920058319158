import { DatePicker, Form, Input, Modal, Popconfirm, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MODE_PAYROLL } from 'constants/payroll';
import { actions, selectPayroll } from 'features/payroll/payrollSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'styles/buttonStyled';

const { setErrorMessage } = actions;

export const PayRunModal = (props: any) => {
  const { callBack, currentItem, mode, setMode, setCurrentItem, setIsPayroll } = props;
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const error = useAppSelector(selectPayroll)?.error;
  const { t } = useTranslation([
    'validation',
    'menu',
    'action',
    'overtime',
    'timesheet',
    'payroll',
  ]);

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'name',
          errors: error?.fieldErrors?.name,
        },
        {
          name: 'payPeriod',
          errors: error?.fieldErrors?.period,
        },
      ]);
      notification.destroy();
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (mode && mode !== '') {
      setVisible(true);
    }
  }, [mode]);

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue({
        name: currentItem?.name,
        payPeriod: currentItem?.period ? moment(currentItem?.period, 'YYYY-MM') : null,
        description: currentItem?.description,
      });
    }
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  const handleClose = () => {
    setMode('');
    setCurrentItem(null);
    setVisible(false);
    setIsPayroll(null);
  };

  return (
    mode !== MODE_PAYROLL.VIEWWL && (
      <Modal
        visible={visible}
        title={
          mode === MODE_PAYROLL.ADD
            ? `${t('payroll:add_new_payroll')}`
            : `${t('payroll:edit_payroll')}`
        }
        okText={t('timesheet:ok')}
        cancelText={t('timesheet:Cancel')}
        //width={850}
        maskClosable={false}
        onCancel={handleClose}
        footer={null}
      >
        <Form form={form} labelCol={{ span: 5 }} labelWrap>
          <Form.Item
            label={t('payroll:name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', { field: t('payroll:name') }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', { field: t('payroll:name') }),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('payroll:payPeriod')}
            name="payPeriod"
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', { field: t('payroll:payPeriod') }),
              },
            ]}
          >
            <DatePicker
              disabled={mode === MODE_PAYROLL.UPDATE}
              picker="month"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label={t('payroll:description')} name="description">
            <TextArea />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CustomButton aType="text" onClick={handleClose}>
              Cancel
            </CustomButton>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                callBack && callBack(form, handleClose, currentItem?.id);
              }}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <CustomButton style={{ marginLeft: '3%' }}>Save</CustomButton>
            </Popconfirm>
          </div>
        </Form>
      </Modal>
    )
  );
};
