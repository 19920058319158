import { PlusOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import { useState } from 'react';
import CustomButton from 'styles/buttonStyled';
import AddStage from './AddStage';
import InterviewCol from './InterviewCol';
import ListStage from './ListStage';
import { JobStatus, RecruitJobDetailType } from 'features/recruitment/jobs/components/types';
import { getUser } from 'utils/auth';
import { PermissionType } from 'hrm-common/extensions/enums/personel';

const Hirring = ({
  data,
  handleChange,
}: {
  data?: RecruitJobDetailType;
  handleChange: (value: any) => void;
}) => {
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const viewStageInterview = (currentItem: any) => {
    setCurrentItem(currentItem);
  };
  return (
    <Row gutter={[16, 0]}>
      {/* <Col span={24}>
        {getUser()?.permissions?.includes('RECRUIT_JOB_CREATED') &&
          data?.status !== JobStatus.APPROVED && (
            <CustomButton
              onClick={() => setVisible(true)}
              style={{ marginBottom: '1rem' }}
              icon={<PlusOutlined />}
            >
              Add Stage
            </CustomButton>
          )}
      </Col> */}
      <Col span={currentItem?.eventType === 'Interview' ? 16 : 24}>
        <ListStage
          data={data?.hiringProcess || []}
          handleChange={handleChange}
          isShow={
            getUser()?.permissions?.includes(PermissionType.RECRUIT_JOB_CREATED) &&
            !(data?.status === JobStatus.APPROVED)
          }
          viewStageInterview={viewStageInterview}
        />
      </Col>
      {currentItem?.eventType === 'Interview' && (
        <Col span={8}>
          <InterviewCol data={currentItem} />
        </Col>
      )}
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
        destroyOnClose
      >
        <AddStage setVisible={setVisible} handleChange={handleChange} />
      </Modal>
    </Row>
  );
};

export default Hirring;
