import { Button, Col, Form, Input, Modal, Row, Select, Switch } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  createAutoSendMailConfig,
  getAutoConfigMailIdListByModule,
  getAutoConfigSendMailByModule,
  getListEmailServer,
  updateAutoSendMailConfig,
} from 'features/onboard/onboardAction';
import { selectOnboard } from 'features/onboard/onboardSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const ModalAddRowAutoSendMail = (props: any) => {
  const { isModalVisible, handleCancel, listModule, editRecord, setIsModalVisible } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [moduleValue, setModuleValue] = useState();
  const [listActionId, setListActionId] = useState([]);
  const onboard = useAppSelector(selectOnboard);
  const actionIdListByModule = onboard?.actionIdListByModule;
  const configAutoSendMailData = onboard?.configAutoSendMailData?.result;
  const listMailId = onboard?.mailIdList?.result;
  const { t } = useTranslation(['validation', 'action', 'onboard']);
  const listEmailServer = onboard?.listEmailServer?.result;

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  useEffect(() => {
    dispatch(getListEmailServer({}));
  }, [dispatch]);

  useEffect(() => {
    if (editRecord) {
      form.setFieldsValue({
        module: editRecord.module,
        actionId: editRecord.actionId,
        emailId: editRecord.emailId,
        description: editRecord.description,
        deleteFlag: !editRecord.deleteFlag,
        templateName: editRecord.templateName,
        emailServerId: editRecord.emailServerId,
      });
      setModuleValue(editRecord.module);
      dispatch(getAutoConfigSendMailByModule(editRecord.module));
      dispatch(getAutoConfigMailIdListByModule(editRecord.module));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRecord]);

  useEffect(() => {
    const currentFormValue = form.getFieldsValue(['actionId', 'emailId', 'deleteFlag']);
    if (!editRecord) {
      if (moduleValue) {
        if (
          currentFormValue.actionId !== undefined ||
          currentFormValue.emailId !== undefined ||
          currentFormValue !== false
        ) {
          form.resetFields(['actionId', 'emailId', 'deleteFlag', 'description', 'templateName']); //reset form when change module and have value in another fields
        }
        dispatch(getAutoConfigSendMailByModule(moduleValue));
        dispatch(getAutoConfigMailIdListByModule(moduleValue));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleValue]);

  useEffect(() => {
    setListActionId(actionIdListByModule);
  }, [actionIdListByModule]);

  useEffect(() => {
    if (isModalVisible === false) {
      form.resetFields();
      setModuleValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const onActionIdChange = (value: any) => {
    // eslint-disable-next-line array-callback-return
    const descriptionByChosenValue = actionIdListByModule?.filter((item: any) => {
      if (item.actionId === value) {
        return item;
      }
    });
    form.setFieldsValue({
      description: descriptionByChosenValue[0].description,
    });
  };

  const onMailTemplateNameChange = (value: any) => {
    // eslint-disable-next-line array-callback-return
    const chosenTemplateDescription = listMailId.filter((item: any) => {
      if (item.id === value) {
        return item;
      }
    });
    form.setFieldsValue({
      templateName: chosenTemplateDescription[0]?.name,
    });
  };

  const onFinish = (values: any) => {
    if (editRecord) {
      const updateParam = {
        ...values,
        deleteFlag: !values?.deleteFlag,
        id: editRecord.id,
      };
      dispatch(updateAutoSendMailConfig(updateParam));
    } else {
      dispatch(createAutoSendMailConfig({ ...values, deleteFlag: !values?.deleteFlag }));
    }
    setIsModalVisible(false);
  };

  const disableActionIdField = () => {
    if (editRecord) {
      return true;
    } else {
      if (moduleValue) {
        return false;
      } else {
        return true;
      }
    }
  };

  const children: any = [];
  if (Array.isArray(listActionId)) {
    listActionId?.forEach((item: any) => {
      children.push(
        <Option
          key={item.actionId}
          disabled={configAutoSendMailData?.find((action: any) =>
            action.actionId === item.actionId ? true : false,
          )}
        >
          {item.name}
        </Option>,
      );
    });
  }

  return (
    <Modal
      title={editRecord ? t('action:edit_row') : t('action:add_row')}
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      width={700}
    >
      <Form
        labelAlign="left"
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
      >
        <Row
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Col span={24}>
            <Form.Item
              name="module"
              label={t('onboard:LabelFormEmail.module')}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: t('onboard:LabelFormEmail.module') }),
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                onChange={(value: any) => setModuleValue(value)}
                disabled={editRecord ? true : false}
                showSearch
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {listModule?.map((item: any) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Col span={24}>
            <Form.Item
              name="actionId"
              label="Action"
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Action' }),
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                onChange={onActionIdChange}
                disabled={disableActionIdField()}
                showSearch
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {children}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Col span={24}>
            <Form.Item
              name="emailId"
              label={t('onboard:LabelFormEmail.template_name')}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', {
                    field: t('onboard:LabelFormEmail.template_name'),
                  }),
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                onChange={onMailTemplateNameChange}
                disabled={editRecord ? false : disableActionIdField()}
                showSearch
                filterOption={(input, option) =>
                  (option!.children?.props?.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {listMailId?.map((item: any) => {
                  return (
                    <Option value={item.id}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        {item.name}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Col span={24}>
            <Form.Item
              name="emailServerId"
              label={t('onboard:email_name')}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: t('onboard:email_name') }),
                },
              ]}
              initialValue={listEmailServer ? listEmailServer[0]?.id : null}
            >
              <Select
                style={{ width: 200 }}
                showSearch
                filterOption={(input, option) =>
                  (option!.children?.props?.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {listEmailServer?.map((item: any) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        {item.mailNameFrom}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Col span={24}>
            <Form.Item
              style={{ display: 'none' }}
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Description' }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name="deleteFlag"
              label={t('onboard:active_status')}
              valuePropName="checked"
              initialValue={true}
              tooltip="Apply email for action"
            >
              <Switch defaultChecked={true} checked={form.getFieldValue('deleteFlag')} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginBottom: 'unset', textAlign: 'end' }} {...tailFormItemLayout}>
          <Button style={{ borderRadius: 4 }} htmlType="submit" type="primary">
            {t('action:save')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddRowAutoSendMail;
