import { Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import payrollApi from 'api/payrollApi';
import { ReasonForm } from 'components/Forms';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { Reason } from 'types';
import { getErrorMessage } from 'utils/getErrorMessage';

export function ProbationSalarayConfirm(props: any) {
  const { t } = useTranslation(['timesheet', 'action', 'payroll']);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const { monthYear, fetchListStatusTabPayrollAPI, idDetailsData, isProbationSalaray } = props;

  const handleConfirmOk = async () => {
    try {
      setIsLoadingConfirm(true);
      const response = await payrollApi.confirmProbationSalaray({
        monthYear: monthYear,
        prlIdDetails: idDetailsData,
      });
      setIsLoadingConfirm(false);

      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI();
    } catch (error) {
      setIsLoadingConfirm(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleConfirm = () => {
    Modal.confirm({
      title: (
        <div style={{ fontWeight: 'bold' }}>{t('payroll:confirm_probation_salaray_title')}</div>
      ),
      okButtonProps: { loading: isLoadingConfirm },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: handleConfirmOk,
    });
  };

  const handleReset = async (reason: string) => {
    try {
      const params = {
        monthYear: monthYear,
        prlIdDetails: idDetailsData,
        reason,
      };

      setIsLoadingConfirm(true);
      const response = await payrollApi.resetConfirmProbation(params);
      setIsLoadingConfirm(false);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI();
    } catch (error) {
      setIsLoadingConfirm(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleResetProbationSalaray = () => {
    let reason = '';
    Modal.confirm({
      title: <div style={{ fontWeight: 'bold' }}>{t('action:reset_reason')}</div>,
      content: (
        <>
          <ReasonForm
            onSubmit={(payload: Reason) => {
              if (!payload.reason) return;
              reason = payload.reason;
            }}
          />
          <p style={{ margin: 0, color: 'red', fontStyle: 'italic' }}>
            {t('payroll:payroll_deleted', { monthYear })}
          </p>
        </>
      ),
      okButtonProps: { loading: isLoadingConfirm },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: () => handleReset(reason),
      onCancel: () => (reason = ''),
    });
  };

  return isProbationSalaray ? (
    <>
      <CustomButton loading={false} onClick={handleResetProbationSalaray}>
        {t('action:reset')}
      </CustomButton>
      <ConfirmedButton>{t('Confirmed')}</ConfirmedButton>
    </>
  ) : (
    <CustomButton loading={false} disabled={isLoading} onClick={handleConfirm}>
      {t('Confirm')}
    </CustomButton>
  );
}

const ConfirmedButton = styled.div`
  background-color: var(--cl_success100);
  color: var(--cl_success600);
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  display: inline;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px;
`;
