export const SORT_TYPES = {
  ascend: 'asc',
  descend: 'desc',
};

export const CONTRACT_SALARY_SORT_FIELDS = {
  employeeId: 'employeeId',
  fullName: 'fullName',
  resideStatus: 'resideStatus',
  taxProfile: 'taxProfile',
  salary: 'salary',
  ratio: 'ratio',
};

export const ALLOWANCE_SORT_FIELDS = {
  employeeId: 'employeeId',
  fullName: 'fullName',
};

export const MONTH_YEAR_FORMAT = 'YYYY-MM';

export const YEAR_FORMAT = 'YYYY';

export const ContractSalaryConfirmStatus = {
  APPROVED: 'approved',
};
export enum MODE_PAYROLL {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  VIEWWL = 'VIEWWL',
  VIEW_HISTORY = 'VIEW_HISTORY',
}

export enum TYPE_PAYROLL {
  TITLE = 'title',
  USER = 'user',
}
export const ADJUSTMENT_SALARY_TYPES = {
  ADJUST: 'adjust',
  APPROVED: 'approved',
};

export const FILTER_DEFAULT = {
  sortBy: 'status',
  orderBy: 'asc',
};
export const FILTER_PAYRUN_GROUP = {
  PROBATION: 'Probation',
};

export const KEY_EMPLOYEE_TAB = {
  INSURANCE: 'insurance',
  TIMESHEET: 'timesheet',
  ALLOWANCE: 'allowance',
  BONUS: 'bonus',
  DEPENDENTS: 'dependents',
  ADVANCES: 'advances',
  CONTRACT_SALARY: 'contract_salary',
  ADJUSTMENT_SALARY: 'adjustment_salary',
  PROBATION_SALARAY: 'probation_salaray',
};

export const PAYROLL_YEAR_SORT_FIELDS = {
  employee_id: 'employee_id',
  full_name: 'full_name',
};

export const ADJUSTMENT_SALARY_SORT_FIELDS = {
  employeeId: 'employeeId',
  fullName: 'fullName',
};
