import { RECRUIT_APPROVE_OFFER } from 'constants/permissions';
import { PermissionType } from 'hrm-common/extensions/enums/personel';
import { getUser } from 'utils/auth';

export interface JobHirringProcess {
  deleteFlag: boolean;
  createdAt: string;
  createdBy: string;
  updatedBy?: null;
  updatedAt: string;
  version: number;
  id: string;
  name: string;
  description: string;
  type: string;
  hiringProcessTemplate: HiringProcessTemplate;
  workflowApproval?: null;
}
export interface HiringProcessTemplate {
  id: string;
  process?: HiringProcessEntity[] | null;
  createdAt: string;
}

export interface InterviewersEntity {
  userId: string;
  fullName: string;
}

export interface JobType {
  id: number;
  name: string;
  color: string;
}
//
export interface RecruitJobDetailType {
  deleteFlag: boolean;
  createdAt: string;
  createdBy?: null;
  updatedBy?: null;
  updatedAt: string;
  version: number;
  id: string;
  status: string;
  openDate: string;
  closeDate: string;
  hiringProcess?: HiringProcessEntity[] | null;
  jobNotes?: null;
  jobTemplate: JobTemplate;
}
export interface HiringProcessEntity {
  name: string;
  testType: string;
  eventType: string;
  interviewers?: InterviewersEntity[] | null;
  statusCandidate: string;
  interviewTemplateId: string;
}
export interface InterviewersEntity {
  userId: string;
  fullName: string;
}
export interface JobTemplate {
  deleteFlag: boolean;
  createdAt: string;
  createdBy: string;
  updatedBy?: string | null;
  updatedAt: string;
  version: number;
  id: string;
  isDefault: boolean;
  name: string;
  description: string;
  positionName: string;
  headcount?: number | null;
  location: string;
  department: string;
  minimumSalary: string;
  maximumSalary: string;
  currencySymbol: string;
  requirements?: string | string[] | null;
  jobTypeName: string;
  jobTypeColor: string;
  jobType: { id: number; name: string; color: string };
  template: Template;
}
export interface Template {
  deleteFlag: boolean;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
  updatedAt: string;
  version: number;
  id: string;
  isDefault: boolean;
  name: string;
  description: string;
  positionName: string;
  headcount: number;
  location: string;
  department: string;
  minimumSalary: string;
  maximumSalary: string;
  currencySymbol: string;
  requirements?: string[] | null;
}

export enum PermissionUser {
  RECRUIT_CANDIDATE_INTERVIEW = 'RECRUIT_CANDIDATE_INTERVIEW',
  RECRUIT_JOB_CREATED = 'RECRUIT_JOB_CREATED',
  RECRUIT_JOB_OFFER = 'RECRUIT_JOB_OFFER',
  RECRUIT_JOB_APPROVE = 'RECRUIT_JOB_APPROVE',
  RECRUIT_CREATE_TEMPLATE = 'RECRUIT_CREATE_TEMPLATE',
}

export enum JobStatus {
  NEW = 'New',
  WAITING_FOR_APPROVAL = 'Waiting for approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
}

export const isInterviewer = () => {
  const userPermissions = getUser()?.permissions || [];
  const hasInterviewPermission = userPermissions.includes(
    PermissionUser.RECRUIT_CANDIDATE_INTERVIEW,
  );
  const hasOtherPermissions = [
    PermissionUser.RECRUIT_JOB_CREATED,
    PermissionUser.RECRUIT_JOB_OFFER,
    PermissionUser.RECRUIT_JOB_APPROVE,
    PermissionUser.RECRUIT_CREATE_TEMPLATE,
    RECRUIT_APPROVE_OFFER,
  ].some((permission) => userPermissions.includes(permission));

  return hasOtherPermissions ? false : hasInterviewPermission;
};

export const isApproveOffer = () => {
  const userPermissions = getUser()?.permissions || [];
  const isApproveOffer = userPermissions.includes(RECRUIT_APPROVE_OFFER);
  return isApproveOffer;
};
