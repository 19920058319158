import { CloseCircleFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import { DateRange, DayPicker } from 'react-day-picker';
import styled, { CSSProperties, css } from 'styled-components';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import moment from 'moment';

import { DatePickerIcon } from 'assets/icons';
import { DATE_FORMAT } from 'constants/commons';

import 'react-day-picker/dist/style.css';
import './DateRangePickerField.scss';
import { useTranslation } from 'react-i18next';
import { getLanguage } from 'utils/auth';
import { vi, enUS, ja, ko } from 'date-fns/locale';

interface StyledWrapperProps {
  directionLabel: 'horizontal' | 'vertical';
}

interface StyledFromAndToDateLabelProps {
  isPlaceholder: boolean;
}

interface StyledSelectionProps {
  isError: boolean;
}

export type DateRangePickerFieldProps<T extends FieldValues> = {
  requiredIcon?: boolean;
  label?: string;
  name: Path<T>;
  control: Control<T>;
  format?: string;
  allowClear?: boolean;
  directionLabel?: 'horizontal' | 'vertical';
  labelStyle?: CSSProperties;
  placeholder?: string[];
  disable?: boolean;
  onChange?: (dates?: DateRange) => void;
  disabledDate?: (date: Date) => boolean;
};

export function DateRangePickerField<T extends FieldValues>({
  name,
  control,
  directionLabel = 'vertical',
  label,
  format = DATE_FORMAT,
  allowClear = false,
  placeholder,
  disable,
  onChange: externalOnChange,
  disabledDate,
}: DateRangePickerFieldProps<T>) {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const { t } = useTranslation(['holiday']);

  const fromDate = value?.from
    ? moment(value.from).format(format)
    : placeholder?.[0] || t('holiday:start_date');
  const toDate = value?.to
    ? moment(value.to).format(format)
    : placeholder?.[1] || t('holiday:end_date');

  const resetDateRange = () => {
    onChange({ from: undefined, to: undefined });
    externalOnChange?.({ from: undefined, to: undefined });
  };

  return (
    <StyledContainer>
      <StyledWrapper directionLabel={directionLabel}>
        {label && <StyledLabel directionLabel={directionLabel}>{label}</StyledLabel>}
        {disable ? (
          <StyledSelection isError={Boolean(error?.message)} ref={ref}>
            <StyledSelectLabel>
              <StyledFromAndToDateLabel isPlaceholder={!value?.from}>
                {fromDate}
              </StyledFromAndToDateLabel>
              {' - '}
              <StyledFromAndToDateLabel isPlaceholder={!value?.to}>
                {toDate}
              </StyledFromAndToDateLabel>
            </StyledSelectLabel>

            <DatePickerIcon />

            {allowClear && !disable && (
              <StyledWrapperCloseIcon>
                <CloseCircleFilled
                  onClick={(event) => {
                    event.stopPropagation();
                    resetDateRange();
                  }}
                />
              </StyledWrapperCloseIcon>
            )}
          </StyledSelection>
        ) : (
          <Popover
            content={
              <DayPicker
                mode="range"
                showOutsideDays={true}
                initialFocus
                defaultMonth={value?.from}
                numberOfMonths={2}
                selected={value}
                onSelect={(dates) => {
                  if (!dates) {
                    resetDateRange();
                    return;
                  }
                  onChange(dates);
                  externalOnChange?.(dates);
                }}
                disabled={disabledDate}
                locale={getLanguage() === 'vi' ? vi : enUS}
              />
            }
            overlayClassName="hrm-overlay-popover"
            arrowPointAtCenter={false}
            trigger="click"
            getPopupContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
          >
            <StyledSelection isError={Boolean(error?.message)} ref={ref}>
              <StyledSelectLabel>
                <StyledFromAndToDateLabel isPlaceholder={!value?.from}>
                  {fromDate}
                </StyledFromAndToDateLabel>
                {' - '}
                <StyledFromAndToDateLabel isPlaceholder={!value?.to}>
                  {toDate}
                </StyledFromAndToDateLabel>
              </StyledSelectLabel>

              <DatePickerIcon />

              {allowClear && !disable && (
                <StyledWrapperCloseIcon>
                  <CloseCircleFilled
                    onClick={(event) => {
                      event.stopPropagation();
                      resetDateRange();
                    }}
                  />
                </StyledWrapperCloseIcon>
              )}
            </StyledSelection>
          </Popover>
        )}
      </StyledWrapper>

      {error?.from?.message && <StyledErrorMessage>{error?.from.message}</StyledErrorMessage>}
      {error?.to?.message && <StyledErrorMessage>{error?.to.message}</StyledErrorMessage>}
    </StyledContainer>
  );
}

const StyledContainer = styled.div``;

const StyledWrapper = styled.div`
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'horizontal' &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const StyledLabel = styled.p`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--cl_gray900);
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'vertical' &&
    css`
      margin-bottom: 6px;
    `}
`;

const StyledErrorMessage = styled.div`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

const StyledWrapperCloseIcon = styled.span`
  position: absolute;
  right: 13px;
  background-color: white;
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  opacity: 0;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
`;

const StyledSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 12px;
  border-radius: 6px;
  border: 1px solid var(--cl_neutral300);
  height: 32px;
  width: 100%;
  cursor: pointer;
  position: relative;

  ${(props: StyledSelectionProps) =>
    props?.isError &&
    css`
      border-color: #ff4d4f;
    `}

  &:hover {
    background-color: #f4f4f5;
  }

  &:hover ${StyledWrapperCloseIcon} {
    opacity: 1;
    background-color: #f4f4f5;
  }
`;

const StyledSelectLabel = styled.span`
  min-width: 165px;
`;

const StyledFromAndToDateLabel = styled.span`
  min-width: 70px;
  width: 70px;
  color: ${(props: StyledFromAndToDateLabelProps) => props.isPlaceholder && '#bfbfbf'};
`;
