import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import { TimesheetEmployee } from 'types';
import { isLangEn } from 'utils/text';
import { useAppDispatch } from 'app/hooks';
import { getEmployeeDetail } from 'features/employee/employeeAction';
import { setDefaultCalendar } from 'features/timesheet/pages/timesheetSlice';
import EmployeeTimeSheetModal from '../EmployeeTimeSheetModal';

interface TimesheetByRangeListProps {
  employeeTimesheetList: TimesheetEmployee[];
  totalResult: number;
  loading?: boolean;
  page: number;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  onTableChange?: (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
  isCheckedAll?: boolean;
  setIsCheckedAll?: Dispatch<SetStateAction<boolean>>;
}

interface StyledLateEarlyWrapperProps {
  isPositive: boolean;
}

interface StyledColorWrapperProps {
  isPositive: boolean;
  isNegative: boolean;
}

const StyledLateEarlyWrapper = styled.div`
  color: var(--cl_neutral900);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  ${(props: StyledLateEarlyWrapperProps) =>
    props.isPositive &&
    css`
      color: var(--cl_error500);
    `}
`;

const StyledColorWrapper = styled.div`
  color: var(--cl_neutral900);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  ${(props: StyledColorWrapperProps) =>
    props.isPositive &&
    css`
      color: var(--cl_success500);
    `}
  ${(props: StyledColorWrapperProps) =>
    props.isNegative &&
    css`
      color: var(--cl_error500);
    `}
`;

const TimesheetByRangeList = ({
  employeeTimesheetList,
  totalResult,
  loading,
  page,
  limit,
  setPage,
  setLimit,
  onTableChange,
  isCheckedAll,
  setIsCheckedAll,
}: TimesheetByRangeListProps) => {
  const { t } = useTranslation(['timesheet', 'employee', 'overtime', 'dashboard', 'masterConfig']);
  const dispatch = useAppDispatch();
  const [openModalTimeSheet, setOpenModalTimeSheet] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState('');

  useEffect(() => {
    dispatch(setDefaultCalendar());
  }, [dispatch]);

  const COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'employeeId',
      key: 'employeeId',
      align: 'center',
      width: 70,
      minWidth: 70,
      ellipsis: true,
      // sorter: true,
      render: (text: any, record: TimesheetEmployee) => <div>{text}</div>,
    },
    {
      title: t('Employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      // sorter: true,
      render: function (text: any, record: any) {
        return (
          <EmployeeColumn
            avatar={record?.fullName}
            fullName={record?.fullName}
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('position'),
      dataIndex: 'position',
      key: 'position',
      ellipsis: true,
      width: 140,
      minWidth: 140,
      // sorter: true,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('dashboard:gantt.date'),
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      width: 100,
      minWidth: 100,
      // sorter: true,
    },
    {
      title: t('masterConfig:manage_place.time'),
      dataIndex: 'time',
      key: 'time',
      ellipsis: true,
      width: 100,
      minWidth: 100,
    },
    {
      title: t('employee:employee_information_fields.location'),
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
      width: 140,
      minWidth: 140,
    },
    {
      title: t('employee:adjustment_information_fields.type'),
      dataIndex: 'typeCheckin',
      key: 'typeCheckin',
      ellipsis: true,
      width: 100,
      minWidth: 100,
    },
  ];

  const handleOpenPopupTimesheet = (record: TimesheetEmployee) => {
    dispatch(getEmployeeDetail({ idItem: record.userId }));
    setOpenModalTimeSheet(true);
    setCurrentEmployeeId(record.userId);
  };

  const handleCancelModal = () => {
    setOpenModalTimeSheet(false);
    setCurrentEmployeeId('');
    dispatch(setDefaultCalendar());
  };

  return (
    <>
      <ITVTable
        columns={COLUMNS}
        data={employeeTimesheetList}
        loading={loading}
        rowKey={['employeeId']}
        totalResult={totalResult}
        setSelectedUser={() => {}}
        setOffset={setPage}
        offset={page}
        isRowSelect={false}
        setLimit={setLimit}
        limit={limit}
        onChange={onTableChange}
        isCheckedAll={isCheckedAll}
        setIsCheckedAll={setIsCheckedAll}
      />

      {openModalTimeSheet && (
        <EmployeeTimeSheetModal
          isOpen={openModalTimeSheet}
          onCancel={handleCancelModal}
          currentEmployeeId={currentEmployeeId}
        />
      )}
    </>
  );
};

export default TimesheetByRangeList;
