import { Popover } from 'antd';
import holidayImage from 'assets/images/holiday.png';
import { TimeSheetStatus } from 'hrm-common/extensions/enums/personel';
import moment from 'moment';
import { memo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { formatDate, transMomentTimezone } from 'utils/date';
import camera from '../../../assets/icons/camera.png';
import icon_machine from '../../../assets/icons/icon_machine.png';
import online from '../../../assets/icons/online.png';
import { fomatFromYYHH } from '../contants/formatdate';
import { EditTimeProps, EditType, ViewListType } from '../interfaces';
import ApproveTime from './LeftManager/ApproveTime';
import HrUpdateTime from './LeftManager/HRUpdateTime';
import UpdateTime from './UpdateTime';
import _ from 'lodash';
import { CellCheckinOut } from './CellCheckinOut';
import { EMPTY_TIME_RANGE } from 'constants/commons';

interface CalendarTitleProps {
  value: string | moment.Moment;
  workDaysWithManager?: number[];
  is_value: any;
  requestOverTime: any;
  requestOverTimes?: any;
  requestLeave: any;
  requestLeaves: any;
  currentWorkTime: any;
  refreshData: any;
  requestAdjustTime: any;
  timeFormat: string;
  checkoutTime: string | null;
  checkinTime: string | null;
  isAuto?: boolean;
  calendarValue: moment.Moment;
  datatype: any;
  dayDate: any;
  viewList: ViewListType;
  userId?: string;
  isConfirmed: boolean;
  dayOfmonth?: any;
  typeCheckInOut?: any;
  limitDayEdit?: number;
  currentUser?: any;
  reloadListUser?: any; //this props to get back user again make list user reload
  calendarWorkshifts?: any;
  isPopUpTimesheet?: boolean;
}

const EditTime = (props: EditTimeProps) => {
  if (props.viewList === ViewListType.PERSONNEL_VIEW) {
    return (
      <EditTimeModal onClick={(e: any) => e.stopPropagation()}>
        <UpdateTime {...props} />
      </EditTimeModal>
    );
  }
  if (props.viewList === ViewListType.MANAGE_VIEW) {
    return (
      <EditTimeModal onClick={(e: any) => e.stopPropagation()}>
        <ApproveTime {...props} />
      </EditTimeModal>
    );
  }
  if (props.viewList === ViewListType.ITEM_HR_EXPORT_DETAILS) {
    return (
      <EditTimeModal onClick={(e: any) => e.stopPropagation()}>
        <HrUpdateTime {...props} />
      </EditTimeModal>
    );
  }
};
// @ts-ignore
const CalendaTitle = (props: CalendarTitleProps) => {
  const {
    value,
    is_value,
    requestOverTimes,
    requestLeaves,
    currentWorkTime,
    refreshData,
    requestAdjustTime,
    timeFormat,
    checkoutTime,
    checkinTime,
    isAuto,
    calendarValue,
    datatype,
    dayDate,
    viewList,
    userId,
    isConfirmed,
    dayOfmonth,
    typeCheckInOut,
    limitDayEdit,
    currentUser,
    reloadListUser,
    calendarWorkshifts,
    workDaysWithManager,
    isPopUpTimesheet = false,
  } = props;

  const valueCalendar = moment.isMoment(value) ? value.format() : value;
  const daygetDate = moment(dayDate).format('DD');
  const monthNow = moment(dayDate).format('MM');
  const yearNow = moment(dayDate).format('YYYY');
  const dateValue = moment.isMoment(value) && value.date();
  const monthValue = moment(value).format('MM');
  const yearValue = moment(value).format('YYYY');
  const is_Month = moment(calendarValue).format('MM');
  const setSundayOrSaturday = moment(value).format('ddd');

  let isShow = useRef(false);
  const handleVisible = (visible: boolean) => {
    isShow.current = visible;
    setVisible(visible);
  };
  const [visible, setVisible] = useState(false);
  //view leave
  const [, setViewLeavePopUp] = useState(false);
  let isShowLeave = useRef(false);
  const handleVisibleLeavePopUp = (visible: boolean) => {
    isShowLeave.current = visible;
    setViewLeavePopUp(visible);
  };
  //ot
  const [, setViewOtPopUp] = useState(false);
  let isShowOt = useRef(false);
  const handleVisibleOtPopUp = (visible: boolean) => {
    isShowOt.current = visible;
    setViewOtPopUp(visible);
  };

  const getEditTime = () => {
    return (
      <>
        {
          // @ts-ignore
          <EditTime
            {...(requestAdjustTime ? requestAdjustTime : currentWorkTime)}
            checkinTime={
              currentWorkTime?.checkinTime
                ? transMomentTimezone(currentWorkTime?.checkinTime).format()
                : transMomentTimezone().set({ hour: 0, minute: 0 })
            }
            checkoutTime={
              currentWorkTime?.checkoutTime
                ? transMomentTimezone(currentWorkTime?.checkoutTime).format()
                : transMomentTimezone().set({ hour: 0, minute: 0 })
            }
            editType={EditType.ADJUST}
            currentDay={
              currentWorkTime === undefined
                ? transMomentTimezone(valueCalendar).format()
                : transMomentTimezone(currentWorkTime?.currentDay).format()
            }
            currentWorkTime={currentWorkTime}
            refreshData={refreshData}
            calendarValue={calendarValue}
            viewList={viewList}
            userId={userId}
            isConfirmed={isConfirmed}
            value={value}
            limitDayEdit={limitDayEdit}
            handleVisible={handleVisible}
            isShow={isShow}
            reloadListUser={reloadListUser}
            calendarWorkshifts={calendarWorkshifts}
          />
        }
      </>
    );
  };
  const getEditTimeLeave = (requestLeave: any) => {
    return (
      <>
        {
          // @ts-ignore
          <EditTime
            {...requestLeave}
            checkinTime={transMomentTimezone(currentWorkTime.checkinTime).format()}
            checkoutTime={transMomentTimezone(currentWorkTime.checkoutTime).format()}
            editType={EditType.LEAVE}
            currentDay={transMomentTimezone(currentWorkTime.currentDay).format()}
            refreshData={refreshData}
            currentWorkTime={currentWorkTime}
            calendarValue={calendarValue}
            datatype={datatype}
            viewList={viewList}
            userId={userId}
            isShowLeave={isShowLeave}
            handleVisibleLeavePopUp={handleVisibleLeavePopUp}
            reloadListUser={reloadListUser}
          />
        }
      </>
    );
  };
  const getEditTimeOt = (requestOverTime: any) => {
    return (
      <>
        {
          // @ts-ignore
          <EditTime
            {...requestOverTime}
            checkinTime={transMomentTimezone(currentWorkTime.checkinTime).format()}
            checkoutTime={transMomentTimezone(currentWorkTime.checkoutTime).format()}
            editType={EditType.OVERTIME}
            currentDay={transMomentTimezone(currentWorkTime.currentDay).format()}
            refreshData={refreshData}
            currentWorkTime={currentWorkTime}
            calendarValue={calendarValue}
            datatype={datatype}
            viewList={viewList}
            userId={userId}
            isShowOt={isShowOt}
            handleVisibleOtPopUp={handleVisibleOtPopUp}
            reloadListUser={reloadListUser}
          />
        }
      </>
    );
  };
  const viewBody = () => {
    if (dayOfmonth?.workDayPerMonth !== undefined) {
      switch (dateValue) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
          return setCheckCalendaDay();
        case 29:
          return setCheckCalendaDay();
        case 30:
          return setCheckCalendaDay();
        case 31:
          return setCheckCalendaDay();
      }
    }
  };
  const setCheckCalendaDay = () => {
    if (monthValue === is_Month) {
      if (
        calendarWorkshifts &&
        !calendarWorkshifts?.[0]?.workshift?.[0]?.workDaysInWeek?.includes(value?.day())
      ) {
        return ViewDayOff(value);
      }
      return viewTH(value);
    } else {
      return setViewDsBody();
    }
  };

  const ViewDaySaturday = (value: any) => {
    if (setSundayOrSaturday === 'Sa' || setSundayOrSaturday === 'Sat') {
      if (dayOfmonth?.workDayPerMonth.indexOf(value.date()) === -1) {
        let dayholye = false;
        let holiday =
          value.month() === moment(calendarValue).month() &&
          dayOfmonth?.holidays.find((item: any) => item.date === value.date() && item.isActive);
        if (!holiday) {
          let checkHolyDay = true;
          let styles = { background: '#E4F2FA', color: '#008423' };
          // tim thaays
          return ViewSaDD(styles, dayholye, checkHolyDay);
        } else {
          let checkHolyDay = false;
          //la ngayf nghi ler tim thaays
          let styles = { color: '#000' };
          return ViewSaDD(styles, dayholye, checkHolyDay, holiday.name);
        }
      } else {
        let dayholye = true;
        let checkHolyDay = false;
        let styles = { background: '#E4F2FA', color: '#008423' };
        // tim thaays
        return ViewSaDD(styles, dayholye, checkHolyDay);
      }
    }
  };
  //chung nhar
  const ViewDaySunday = (value: any) => {
    if (setSundayOrSaturday === 'Su' || setSundayOrSaturday === 'Sun') {
      if (dayOfmonth?.workDayPerMonth.indexOf(value.date()) === -1) {
        const dayholye: any = false;
        let holiday =
          value.month() === moment(calendarValue).month() &&
          dayOfmonth?.holidays.find((item: any) => item.date === value.date() && item.isActive);
        // laf ngayf nghi
        //Khoong timf thaays
        if (!holiday) {
          let checkHolyDay: any = true;
          let styles: any = { background: '#FCF8E4', color: '#FF0100' };
          return ViewSaDD(styles, dayholye, checkHolyDay);
        } else {
          let checkHolyDay: any = false;
          let styles: any = { color: '#000' };
          return ViewSaDD(styles, dayholye, checkHolyDay, holiday.name);
        }
      } else {
        // laf ngay ddi lam
        let dayholye: any = true;
        let checkHolyDay: any = false;
        let styles: any = { background: '#FCF8E4', color: '#FF0100' };
        // tim thaays
        return ViewSaDD(styles, dayholye, checkHolyDay);
      }
    }
    return <div style={{ height: 100 }}>{is_value}</div>;
  };

  //day off
  const ViewDayOff = (value: any) => {
    if (
      calendarWorkshifts &&
      !calendarWorkshifts?.[0]?.workshift[0]?.workDaysInWeek?.includes(value.day())
    ) {
      const dayholye: any = false;
      let holiday =
        value.month() === moment(calendarValue).month() &&
        dayOfmonth?.holidays.find((item: any) => item.date === value.date() && item.isActive);
      // laf ngayf nghi
      //Khoong timf thaays
      if (!holiday) {
        let checkHolyDay: any = true;
        let styles: any = { background: '#E4F2FA', color: '#FF0100' };
        return ViewSaDD(styles, dayholye, checkHolyDay);
      } else {
        let checkHolyDay: any = false;
        let styles: any = { color: '#000' };
        return ViewSaDD(styles, dayholye, checkHolyDay, holiday.name);
      }
    } else {
      // laf ngay ddi lam
      let dayholye: any = true;
      let checkHolyDay: any = false;
      let styles: any = { background: '#E4F2FA', color: '#FF0100' };
      // tim thaays
      return ViewSaDD(styles, dayholye, checkHolyDay);
    }
  };

  const viewTH = (value: any) => {
    if (dayOfmonth?.workDayPerMonth.indexOf(value.date()) === -1) {
      let dayholye: any = false;
      //Khoong timf thaays
      let holiday =
        value.month() === moment(calendarValue).month() &&
        dayOfmonth?.holidays.find((item: any) => item.date === value.date() && item.isActive);
      if (!holiday) {
        let dayholye: any = true;
        let checkHolyDay: any = false;
        let styles: any = { background: '#fff', color: '#000' };
        return viewRequetTT(styles, dayholye, checkHolyDay);
      } else {
        let checkHolyDay: any = true;
        let styles: any = {
          color: '#000',
        };
        return viewRequetTT(styles, dayholye, checkHolyDay, holiday.name);
      }
    } else {
      let dayholye: any = true;
      let checkHolyDay: any = false;
      let styles: any = { background: '#fff', color: '#000' };
      // tim thaays
      return viewRequetTT(styles, dayholye, checkHolyDay);
    }
  };

  const ViewSaDD = (styles: any, homeday: any, checkHolyDay: any, holidayName?: string) => {
    return (
      <DateCell
        style={{
          width: '100%',
          height: 110,
          background: holidayName ? '' : `${styles.background}`,
        }}
        data-is-holiday={holidayName ? true : false}
      >
        <div
          style={{
            height: 20,
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div style={{ fontSize: 14, marginLeft: 6, color: `${styles.color}` }}>{is_value}</div>
          <CalendaNoteContainer>
            {viewLeave(styles, homeday, checkHolyDay)}
            <IconCheckinType />
          </CalendaNoteContainer>
        </div>
        {holidayName && (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 500,
              fontStyle: 'italic',
              color: 'crimson',
              padding: '0 6px',
            }}
          >
            {holidayName}
          </div>
        )}
        <DateContent>
          {viewAdjust(styles, homeday, checkHolyDay)}
          {currentWorkTime && viewOverTime(styles, homeday, checkHolyDay)}
        </DateContent>
      </DateCell>
    );
  };

  const viewRequetTT = (styles: any, dayholye: any, checkHolyDay: any, holidayName?: string) => {
    return (
      <DateCell
        style={{
          width: '100%',
          height: 110,
          background: holidayName ? '' : `${styles.background}`,
          overflow: 'hidden',
        }}
        data-is-holiday={holidayName ? true : false}
      >
        <div
          style={{
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
            overflowX: 'auto',
            overflowY: 'hidden',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: 14, marginLeft: 6 }}>{is_value}</div>
          {/* remove note by work shift*/}
          <CalendaNoteContainer>
            {viewLeave(styles, dayholye, checkHolyDay)}
            <IconCheckinType />
          </CalendaNoteContainer>
        </div>
        {holidayName && (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 500,
              fontStyle: 'italic',
              color: 'crimson',
              padding: '0 6px',
              position: 'relative',
              top: '18px',
            }}
          >
            {holidayName}
          </div>
        )}
        <DateContent>
          {viewAdjust(styles, dayholye, checkHolyDay)}
          {viewOverTime(styles, dayholye, checkHolyDay)}
        </DateContent>
        {/* {viewShiftName(styles, dayholye, checkHolyDay)} */}
      </DateCell>
    );
  };

  const viewLeave = (styles: any, dayholye: any, checkHolyDay: any) => {
    return (
      <>
        {requestLeaves?.length
          ? checkHolyDay === false &&
            requestLeaves.map((item: any) => (
              <Popover
                trigger="click"
                overlayClassName={isPopUpTimesheet ? 'itv-popuptimesheet' : 'itv-popover'}
                // visible={viewLeavePopUp}
                // onVisibleChange={handleVisibleLeavePopUp}
                content={getEditTimeLeave(item)}
              >
                <DateTag
                  style={{
                    borderRadius: '25px',
                    height: 18,
                    padding: 6,
                    margin: 0,
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                  status={item.status}
                  // al={true}
                  onClick={(e: any) => e.stopPropagation()}
                  title={item?.type || 'Not type leave off!'}
                >
                  {item.code}
                </DateTag>
              </Popover>
            ))
          : null}
      </>
    );
  };

  const viewOverTime = (styles: any, dayholye: any, checkHolyDay: any) => {
    return requestOverTimes?.map((item: any) => (
      <Popover
        trigger="click"
        overlayClassName={isPopUpTimesheet ? 'itv-popuptimesheet' : 'itv-popover'}
        // visible={viewOtPopUp}
        // onVisibleChange={handleVisibleOtPopUp}
        content={getEditTimeOt(item)}
        placement="right"
      >
        <DateTag status={item.status} onClick={(e: any) => e.stopPropagation()}>
          {`${fomatFromYYHH(item.startTime, 'HH:mm')} -
        ${fomatFromYYHH(item.endTime, 'HH:mm')}`}
          <TagOT>OT</TagOT>
        </DateTag>
      </Popover>
    ));
  };

  const viewCheckholyday = (styles: any, dayholye: any, checkHolyDay: any) => {
    return checkHolyDay === true ? null : (
      <DateTag
        style={{
          marginTop: 10,
        }}
        status={requestAdjustTime ? 'working' : 'working'}
      ></DateTag>
    );
  };

  const viewAdjust = (styles: any, dayholye: any, checkHolyDay: any) => {
    const shiftPresent = _(calendarWorkshifts)
      ?.filter((o: any) => o?.workshift?.[0]?.workdays?.includes(+dateValue))
      ?.value()
      ?.pop();

    const isFullTime = shiftPresent?.workshift?.[0]?.isFullTime;
    const hasWorkdaysWithManager = workDaysWithManager?.includes(dateValue as number);
    // const hasWorkdaysWithWorkshift = workDaysWithWorkshift?.includes(dateValue
    function EmptyCheckinOut(props: { children: React.ReactNode }) {
      const { children } = props;
      return dayholye === false ? (
        <DateTag
          style={{ marginTop: 10 }}
          status={requestAdjustTime ? requestAdjustTime.status : 'working'}
        ></DateTag>
      ) : (
        <CellCheckinOut
          onVisibleChange={handleVisible}
          visible={visible}
          content={isFullTime && getEditTime()}
          isFullTime={isFullTime}
          requestAdjustTime={requestAdjustTime}
        >
          {children}
        </CellCheckinOut>
      );
    }

    function HasDataCheckinOut() {
      return (
        <CellCheckinOut
          onVisibleChange={handleVisible}
          visible={visible}
          content={isFullTime && getEditTime()}
          isFullTime={isFullTime}
          requestAdjustTime={requestAdjustTime}
        >
          {`${
            requestAdjustTime?.status === TimeSheetStatus.APPROVED
              ? formatDate(requestAdjustTime.startTime, timeFormat)
              : checkinTime
          } - ${
            requestAdjustTime?.status === TimeSheetStatus.APPROVED
              ? formatDate(requestAdjustTime.endTime, timeFormat)
              : checkoutTime
          }`}
        </CellCheckinOut>
      );
    }
    return (
      <>
        {Number(monthValue) > Number(monthNow) && Number(yearNow) === Number(yearValue) ? (
          ''
        ) : Number(monthValue) < Number(monthNow) && Number(yearNow) === Number(yearValue) ? (
          currentWorkTime === undefined ? (
            workDaysWithManager && workDaysWithManager?.length > 0 ? (
              Number(is_Month) === Number(monthValue) &&
              hasWorkdaysWithManager &&
              (checkHolyDay === true ? (
                <DateTag
                  style={{ marginTop: 10 }}
                  status={requestAdjustTime ? requestAdjustTime.status : 'working'}
                ></DateTag>
              ) : (
                <EmptyCheckinOut>
                  {(currentUser?.endDate &&
                    transMomentTimezone(valueCalendar).isAfter(
                      transMomentTimezone(currentUser?.endDate).endOf('date'),
                    )) ||
                  (currentUser?.startDate &&
                    transMomentTimezone(valueCalendar).isBefore(
                      transMomentTimezone(currentUser?.startDate).endOf('date'),
                    )) ||
                  !shiftPresent
                    ? ''
                    : EMPTY_TIME_RANGE}
                </EmptyCheckinOut>
              ))
            ) : (
              Number(is_Month) === Number(monthValue) &&
              (checkHolyDay === true ? (
                <DateTag
                  style={{ marginTop: 10 }}
                  status={requestAdjustTime ? requestAdjustTime.status : 'working'}
                ></DateTag>
              ) : (
                <EmptyCheckinOut>
                  {(currentUser?.endDate &&
                    transMomentTimezone(valueCalendar).isAfter(
                      transMomentTimezone(currentUser?.endDate).endOf('date'),
                    )) ||
                  (currentUser?.startDate &&
                    transMomentTimezone(valueCalendar).isBefore(
                      transMomentTimezone(currentUser?.startDate).endOf('date'),
                    )) ||
                  calendarWorkshifts?.length === 0
                    ? ''
                    : EMPTY_TIME_RANGE}
                </EmptyCheckinOut>
              ))
            )
          ) : (
            <HasDataCheckinOut />
          )
        ) : Number(monthNow) === Number(monthValue) && Number(yearNow) === Number(yearValue) ? (
          Number(daygetDate) <= Number(is_value) ? (
            Number(daygetDate) === Number(is_value) ? (
              currentWorkTime === undefined ? null : (
                <HasDataCheckinOut />
              )
            ) : null
          ) : currentWorkTime === undefined ? (
            isAuto === true ? (
              <>
                {/*{`${requestAdjustTime?.status === TimeSheetStatus.APPROVED ? '00:00' : '00:00'} -*/}
                {/*${requestAdjustTime?.status === TimeSheetStatus.APPROVED ? '00:00' : '00:00'}`}*/}
              </>
            ) : workDaysWithManager && workDaysWithManager?.length > 0 ? (
              Number(is_Month) === Number(monthValue) &&
              hasWorkdaysWithManager && (
                <EmptyCheckinOut>{!shiftPresent ? '' : EMPTY_TIME_RANGE}</EmptyCheckinOut>
              )
            ) : (
              Number(is_Month) === Number(monthValue) && (
                <EmptyCheckinOut>{!shiftPresent ? '' : EMPTY_TIME_RANGE}</EmptyCheckinOut>
              )
            )
          ) : dayholye === false ? (
            currentWorkTime?.checkinTime != null ? (
              <HasDataCheckinOut />
            ) : (
              <>{viewCheckholyday(styles, dayholye, checkHolyDay)}</>
            )
          ) : (
            <HasDataCheckinOut />
          )
        ) : currentWorkTime === undefined ? (
          Number(is_Month) === Number(monthValue) ? (
            checkHolyDay === true ? (
              <DateTag
                style={{ marginTop: 10 }}
                status={requestAdjustTime ? requestAdjustTime.status : 'working'}
              ></DateTag>
            ) : dayholye === false ? (
              <DateTag
                style={{ marginTop: 10 }}
                status={requestAdjustTime ? requestAdjustTime.status : 'working'}
              ></DateTag>
            ) : (
              <EmptyCheckinOut>{!shiftPresent ? '' : EMPTY_TIME_RANGE}</EmptyCheckinOut>
            )
          ) : (
            <div></div>
          )
        ) : (
          <HasDataCheckinOut />
        )}
      </>
    );
  };

  const viewShiftName = (styles: any, dayholye: any, checkHolyDay: any) => {
    const shiftPresent = _(calendarWorkshifts)
      ?.filter((o: any) => o?.workshift?.[0]?.workdays?.includes(+dateValue))
      ?.value()
      ?.pop();

    const hasWorkdaysWithManager = workDaysWithManager?.includes(dateValue as number);

    return (
      <>
        {Number(monthValue) > Number(monthNow) && Number(yearNow) === Number(yearValue) ? (
          ''
        ) : Number(monthValue) < Number(monthNow) && Number(yearNow) === Number(yearValue) ? (
          currentWorkTime === undefined ? (
            workDaysWithManager && workDaysWithManager?.length > 0 ? (
              Number(is_Month) === Number(monthValue) &&
              hasWorkdaysWithManager &&
              (checkHolyDay === true ? '' : '')
            ) : (
              Number(is_Month) === Number(monthValue) &&
              (checkHolyDay === true ? (
                ''
              ) : (
                <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
              ))
            )
          ) : (
            <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
          )
        ) : Number(monthNow) === Number(monthValue) && Number(yearNow) === Number(yearValue) ? (
          Number(daygetDate) <= Number(is_value) ? (
            Number(daygetDate) === Number(is_value) ? (
              currentWorkTime === undefined ? null : (
                <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
              )
            ) : null
          ) : currentWorkTime === undefined ? (
            isAuto === true ? (
              <></>
            ) : workDaysWithManager && workDaysWithManager?.length > 0 ? (
              Number(is_Month) === Number(monthValue) &&
              hasWorkdaysWithManager && (
                <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
              )
            ) : (
              Number(is_Month) === Number(monthValue) && (
                <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
              )
            )
          ) : dayholye === false ? (
            currentWorkTime?.checkinTime != null ? (
              <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
            ) : (
              <>{viewCheckholyday(styles, dayholye, checkHolyDay)}</>
            )
          ) : (
            <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
          )
        ) : currentWorkTime === undefined ? (
          Number(is_Month) === Number(monthValue) ? (
            checkHolyDay === true ? (
              <DateTag
                style={{ marginTop: 10 }}
                status={requestAdjustTime ? requestAdjustTime.status : 'working'}
              ></DateTag>
            ) : dayholye === false ? (
              <DateTag
                style={{ marginTop: 10 }}
                status={requestAdjustTime ? requestAdjustTime.status : 'working'}
              ></DateTag>
            ) : (
              <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
            )
          ) : (
            <div></div>
          )
        ) : (
          <TagShiftname>{shiftPresent?.workshift[0]?.name}</TagShiftname>
        )}
      </>
    );
  };

  const IconCheckinType = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {(() => {
          switch (typeCheckInOut) {
            case 'online':
              return (
                <Popover content="online">
                  <IconType src={online} />
                </Popover>
              );
            case 'machine':
              return (
                <Popover content="machine">
                  <IconType src={icon_machine} />
                </Popover>
              );
            case 'camera':
              return (
                <Popover content="camera">
                  <IconType src={camera} />
                </Popover>
              );
            default:
              return <div></div>;
          }
        })()}
      </div>
    );
  };

  const setViewDsBody = () => {
    let dayholye: any = true;
    if (setSundayOrSaturday === 'Sa') {
      let checkHolyDay: any = true;
      let styles: any = { background: '#E4F2FA', color: '#91d0a3' };
      let homeday: any = false;
      // tim thaays
      return ViewSaDD(styles, homeday, checkHolyDay);
    }
    if (setSundayOrSaturday === 'Su') {
      let homeday: any = false;
      let checkHolyDay: any = true;
      let styles: any = { background: '#FCF8E4', color: '#eeb5b5' };
      // tim thaays
      return ViewSaDD(styles, homeday, checkHolyDay);
    }
    let checkHolyDay: any = false;
    let styles: any = { background: '#fff', color: '#000' };
    return viewRequetTT(styles, dayholye, checkHolyDay);
  };

  return <>{viewBody()}</>;
};
export default memo(CalendaTitle);

export const Container = styled.div`
  margin-top: 20px;
  display: flex;

  .ant-popover-inner-content {
    padding: 0;
  }

  @media screen and (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;

export const ContainerLeft = styled.div`
  width: 75%;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const ContainerRight = styled.div`
  width: 25%;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const StatusFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const DateCell = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${holidayImage});
  background-size: 100% 100%;
`;

interface DateTagProps {
  status: string;
  type?: 'adjust';
  workType?: 'FT' | 'PT';
  al?: boolean;
}

export const DateTag = styled.div`
  border-radius: 5px;
  text-align: center;
  margin: 10px 8%;
  height: 21px;
  width: 86%;
  font-size: 12px;
  @media screen and (max-width: 1440px) {
    font-size: 10px;
  }
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;

  ${(props: DateTagProps) =>
    props.type === 'adjust' &&
    css`
      &:after {
        content: '${props.workType}';
        position: relative;
        font-size: 10px;
        font-weight: 500;
        color: ${props.workType === 'FT'
          ? 'var(--cl_error500)'
          : props.workType === 'PT'
          ? 'var(--cl_info500)'
          : 'black'};
        top: -4px;
        right: -3px;
      }
    `}

  /* cursor: grabbing; */
  ${(props: DateTagProps) =>
    props.al &&
    css`
      width: 40px;
    `}
  ${(props: DateTagProps) => {
    switch (props.status) {
      case 'working':
        return css`
          /* box-shadow: 0 0 4px rgb(0 0 0 / 20%); */
        `;
      case 'adjust':
        return css`
          background-color: var(--cl_info500);
          box-shadow: 0 0 4px rgb(0 0 0 / 20%);
        `;
      case TimeSheetStatus.APPROVED:
        return css`
          background-color: var(--cl_success100);
          box-shadow: 0 0 4px rgb(0 0 0 / 20%);
        `;
      case TimeSheetStatus.PENDING:
        return css`
          background-color: var(--cl_warning100);
          box-shadow: 0 0 4px rgb(0 0 0 / 20%);
        `;
      case TimeSheetStatus.REJECTED:
        return css`
          background-color: var(--cl_error100);
          box-shadow: 0 0 4px rgb(0 0 0 / 20%);
        `;
      default:
        break;
    }
  }}
`;
export const DateContent = styled.div`
  height: 90px;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
`;

export const IconType = styled.img`
  width: 22px;
  height: 22px;
  padding: 2px;
`;

export const CalendaNoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
`;

export const EditTimeModal = styled.div`
  background-color: white;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
  border-radius: 5px;
  height: 100%;
  padding: 10px 10px 20px 10px;
  border-top-width: 7px;
  border-top-style: solid;
  border-top-color: var(--cl_info500);
`;
export const TagOT = styled.div`
  margin-left: 3px;
  margin-top: -7px;
  font-size: 10px;
  color: var(--cl_error500);
`;
export const TagShiftname = styled.p`
  font-size: 10px;
  color: black;
  text-align: center;
`;
