import {
  getAllAdvanceUser,
  getAllAllowanceListForUser,
  getAllAllowanceUser,
  getAllBonusListForUser,
  getAllBonusUser,
  getAllDependentsUser,
  getAllUsersHaveAllowance,
  getAllUsersHaveBonus,
  getAllUsersHaveDependents,
  getFindOnePlanUser,
  getContractUser,
  searchEmployee,
  getAllAdvanceListForUser,
  getAllUsersHaveAdvance,
  getAllAdjustmentUser,
  getAllUsersHaveAdjustment,
  getManagerList,
  getEmployeeListProbation,
} from 'features/employee/employeeAction';
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  getEmployeeList,
  getEmployeeListApproved,
  getEmployeeDetail,
  getEmployeeRecordHistory,
  createEmployee,
  updateEmployee,
  updateEmployeeId,
  deleteEmployee,
  requestApproveEmployee,
  saveAndRequestApproveEmployee,
  approveEmployee,
  saveAndApproveEmployee,
  activeDeactiveEmployee,
  exportEmployees,
  filterSortEmployeeList,
} from './employeeAction';
import { IEmployee, IEmployeeDetail } from 'hrm-common/extensions/interfaces/personel';
import { DEFAULT_SIZE_PAGE, DEFAULT_CURRENT_PAGE } from 'hrm-common/extensions/constant/personel';
import { isMobile } from 'react-device-detect';

const initialState: any = {
  error: null,
  isGettingEmployees: false,
  employeeList: [],
  employeeFilterList: [],

  total: 0,
  totalSearch: 0,
  searchCriteria: {},

  isSearchingEmployee: false,
  employeeSearchList: [],

  employeeListApproved: [],
  employeeDetail: {} as IEmployeeDetail,
  isGettingDetail: false,

  isGettingHistory: false,
  employeeRecordHistory: [],
  totalRecordHistory: 0,

  isExporting: false,
  lstColTableEmployee: [],

  isDeactiveMySelf: false,
  sizePage: DEFAULT_SIZE_PAGE,
  currentPage: isMobile ? 5 : DEFAULT_CURRENT_PAGE,
  selectRowKey: [],
  selectExpand: [],
  isCheckAll: false,
  loadingListApproved: false,

  dataAssignAllowance: {},
  dataListAllowance: {},
  dataUsersHaveAllowance: {},
  loadingAllowance: false,

  dataAssignAdvance: {},
  dataListAdvances: {},
  dataFindOnePlanUser: {},
  dataUsersHaveAdvance: {},

  dataAssignBonus: {},
  dataListBonus: {},
  dataUsersHaveBonus: {},
  loadingBonus: false,

  dataAssignDependents: {},
  dataUsersHaveDependents: {},
  loadingDependents: false,

  dataUserContract: {},
  loadingContract: false,

  dataAssignAdjustment: {},
  dataListAdjustment: {},
  dataUsersHaveAdjustment: {},
  loadingAdjustment: false,

  listManager: null,
};

const employee = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployeeFilterList(state, action: PayloadAction<IEmployee['employeeFilterList']>) {
      state.employeeFilterList = action.payload;
    },
    setSizePageOrCurrentPage(
      state,
      action: PayloadAction<{
        sizePage?: IEmployee['sizePage'];
        currentPage?: IEmployee['currentPage'];
      }>,
    ) {
      const { sizePage = state.sizePage, currentPage = state.currentPage } = action.payload;
      state.sizePage = sizePage;
      state.currentPage = currentPage;
    },
    setCheckRow(state, action) {
      state.selectRowKey = action.payload;
    },
    setCheckExpand(state, action) {
      state.selectExpand = action.payload;
    },
    setCheckAllStatus(state, action) {
      state.isCheckAll = action.payload;
    },
    resetStateEmployee(state, action) {
      state.employeeDetail = {};
    },
    resetStateEmployeeList(state, action) {
      state.employeeList = [];
    },
    setErrorMessage(state, action) {
      state.error = action.payload;
    },
    resetDataFindOnePlanUser(state, action) {
      state.dataFindOnePlanUser = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeList.pending, (state, action: any) => {
        state.isGettingEmployees = true;
        state.error = null;
        state.searchCriteria = { ...action.payload };
      })
      .addCase(getEmployeeList.fulfilled, (state, action) => {
        state.isGettingEmployees = false;
        state.error = null;
        state.employeeList = action.payload.data?.result;
        state.total = action.payload.data?.totalResult;
      })
      .addCase(getEmployeeList.rejected, (state, action: any) => {
        state.isGettingEmployees = false;
        state.error = action.payload;
        state.employeeList = [];
      })

      .addCase(searchEmployee.pending, (state, action: any) => {
        state.isGettingEmployees = true;
        state.error = null;
      })
      .addCase(searchEmployee.fulfilled, (state, action) => {
        state.isGettingEmployees = false;
        state.error = null;
        state.employeeSearchList = action.payload.result;
        state.total = action.payload.totalResult;
      })
      .addCase(searchEmployee.rejected, (state, action: any) => {
        state.isGettingEmployees = false;
        state.error = action.payload;
        state.employeeSearchList = [];
      })

      .addCase(getEmployeeListApproved.pending, (state, action) => {
        state.loadingListApproved = true;
        state.error = null;
        state.employeeListApproved = [];
      })
      .addCase(getEmployeeListApproved.fulfilled, (state, action: any) => {
        state.loadingListApproved = false;
        state.error = null;
        state.employeeListApproved = action.payload.data?.result;
      })
      .addCase(getEmployeeListApproved.rejected, (state, action: any) => {
        state.loadingListApproved = false;
        state.error = action?.payload?.message;
        state.employeeListApproved = [];
      })
      .addCase(getEmployeeListProbation.pending, (state, action) => {
        state.loadingListApproved = true;
        state.error = null;
        state.employeeListApproved = [];
      })
      .addCase(getEmployeeListProbation.fulfilled, (state, action: any) => {
        state.loadingListApproved = false;
        state.error = null;
        state.employeeListApproved = action.payload.data?.result;
      })
      .addCase(getEmployeeListProbation.rejected, (state, action: any) => {
        state.loadingListApproved = false;
        state.error = action?.payload?.message;
        state.employeeListApproved = [];
      })

      .addCase(getEmployeeDetail.pending, (state, action) => {
        state.isGettingDetail = true;
        state.error = null;
        state.employeeDetail = {} as IEmployeeDetail;
      })
      .addCase(getEmployeeDetail.fulfilled, (state, action: any) => {
        state.isGettingDetail = false;
        state.error = null;
        state.employeeDetail = action.payload?.data;
      })
      .addCase(getEmployeeDetail.rejected, (state, action: any) => {
        state.isGettingDetail = false;
        state.error = action.payload.message;
        state.employeeDetail = {} as IEmployeeDetail;
      })

      .addCase(getEmployeeRecordHistory.pending, (state, action: any) => {
        state.isGettingHistory = true;
        state.error = null;
        state.employeeRecordHistory = [];
      })
      .addCase(getEmployeeRecordHistory.fulfilled, (state, action) => {
        state.isGettingHistory = false;
        state.error = null;
        state.employeeRecordHistory = action.payload.data?.result;
        state.totalRecordHistory = action.payload.data?.total;
      })
      .addCase(getEmployeeRecordHistory.rejected, (state, action: any) => {
        state.isGettingHistory = false;
        state.error = action.payload;
        state.employeeRecordHistory = [];
      })

      .addCase(createEmployee.pending, (state, action) => {
        state.error = null;
      })
      .addCase(createEmployee.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse.message;
        state.employeeList = action.payload.employeeListNew
          ? action.payload.employeeListNew
          : state.employeeList;
        state.total = state.employeeList?.length;
      })
      .addCase(createEmployee.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(updateEmployee.pending, (state, action) => {
        state.error = null;
      })
      .addCase(updateEmployee.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse?.message;
        state.employeeList = action.payload.employeeListNew
          ? action.payload.employeeListNew
          : state.employeeList;
        state.total = action.payload.totalResult || state.employeeList?.length; //set total when update data from table
      })
      .addCase(updateEmployee.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(updateEmployeeId.pending, (state, action) => {
        state.error = null;
      })
      .addCase(updateEmployeeId.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse?.message;
        state.employeeList = action.payload.employeeListNew
          ? action.payload.employeeListNew
          : state.employeeList;
      })
      .addCase(updateEmployeeId.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(deleteEmployee.pending, (state, action) => {
        state.error = null;
      })
      .addCase(deleteEmployee.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse.message;
        state.employeeList = action.payload.newEmployeeList;
        state.total = state.employeeList?.length;
      })
      .addCase(deleteEmployee.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(requestApproveEmployee.pending, (state, action) => {
        state.error = null;
      })
      .addCase(requestApproveEmployee.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse.message;
        state.employeeList = action.payload.employeeListNew
          ? action.payload.employeeListNew
          : state.employeeList;
      })
      .addCase(requestApproveEmployee.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(saveAndRequestApproveEmployee.pending, (state, action) => {
        state.error = null;
      })
      .addCase(saveAndRequestApproveEmployee.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse.message;
        state.employeeList = action.payload.employeeListNew
          ? action.payload.employeeListNew
          : state.employeeList;
        state.total = state.employeeList?.length;
      })
      .addCase(saveAndRequestApproveEmployee.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(approveEmployee.pending, (state, action) => {
        state.error = null;
      })
      .addCase(approveEmployee.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse.message;
        state.employeeList = action.payload.employeeListNew
          ? action.payload.employeeListNew
          : state.employeeList;
      })
      .addCase(approveEmployee.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(saveAndApproveEmployee.pending, (state, action) => {
        state.error = null;
      })
      .addCase(saveAndApproveEmployee.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse.message;
        state.employeeList = action.payload.employeeListNew
          ? action.payload.employeeListNew
          : state.employeeList;
        state.total = state.employeeList?.length;
      })
      .addCase(saveAndApproveEmployee.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(activeDeactiveEmployee.pending, (state, action) => {
        state.error = null;
        state.isDeactiveMySelf = false;
      })
      .addCase(activeDeactiveEmployee.fulfilled, (state, action: any) => {
        state.error = action.payload.dataResponse.message;
        state.employeeList = action.payload.employeeListNew
          ? action.payload.employeeListNew
          : state.employeeList;
        state.isDeactiveMySelf = action.payload.isDeactiveMySelf;
      })
      .addCase(activeDeactiveEmployee.rejected, (state, action: any) => {
        state.error = action.payload;
        state.isDeactiveMySelf = false;
      })

      .addCase(exportEmployees.pending, (state, action: any) => {
        state.isExporting = true;
      })
      .addCase(exportEmployees.fulfilled, (state, action: any) => {
        state.isExporting = false;
      })
      .addCase(exportEmployees.rejected, (state, action: any) => {
        state.isExporting = false;
        state.error = action.payload;
      })

      .addCase(filterSortEmployeeList.pending, (state, action: any) => {
        state.isGettingEmployees = true;
        state.error = null;
        state.isGettingEmployees = true; //table loading
      })
      .addCase(filterSortEmployeeList.fulfilled, (state, action: any) => {
        state.isGettingEmployees = false;
        state.employeeList = action.payload?.data?.result;
        state.totalSearch = action.payload?.data?.totalSearch;
        state.total = action.payload?.data?.totalResult;
      })
      .addCase(filterSortEmployeeList.rejected, (state, action: any) => {
        state.isGettingEmployees = false;
        state.error = action.payload;
      })

      .addCase(getAllAllowanceUser.pending, (state, action: any) => {
        state.loadingAllowance = true;
        state.error = null;
      })
      .addCase(getAllAllowanceUser.fulfilled, (state, action: any) => {
        state.loadingAllowance = false;
        state.dataAssignAllowance = action.payload?.data;
      })
      .addCase(getAllAllowanceUser.rejected, (state, action: any) => {
        state.loadingAllowance = false;
        state.error = action.payload;
      })
      .addCase(getAllAllowanceListForUser.pending, (state, action: any) => {
        state.loadingAllowance = true;
        state.error = null;
      })
      .addCase(getAllAllowanceListForUser.fulfilled, (state, action: any) => {
        state.loadingAllowance = false;
        state.dataListAllowance = action.payload?.data;
      })
      .addCase(getAllAllowanceListForUser.rejected, (state, action: any) => {
        state.loadingAllowance = false;
        state.error = action.payload;
      })
      .addCase(getAllUsersHaveAllowance.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllUsersHaveAllowance.fulfilled, (state, action: any) => {
        state.dataUsersHaveAllowance = action.payload?.data;
      })
      .addCase(getAllUsersHaveAllowance.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      //advances

      .addCase(getAllAdvanceUser.pending, (state, action: any) => {
        state.loadingAllowance = true;
        state.error = null;
      })
      .addCase(getAllAdvanceUser.fulfilled, (state, action: any) => {
        state.loadingAllowance = false;
        state.dataAssignAdvance = action.payload?.data;
      })
      .addCase(getAllAdvanceUser.rejected, (state, action: any) => {
        state.loadingAllowance = false;
        state.error = action.payload;
      })

      .addCase(getAllAdvanceListForUser.pending, (state, action: any) => {
        state.loadingAllowance = true;
        state.error = null;
      })
      .addCase(getAllAdvanceListForUser.fulfilled, (state, action: any) => {
        state.loadingAllowance = false;
        state.dataListAdvances = action.payload?.data;
      })
      .addCase(getAllAdvanceListForUser.rejected, (state, action: any) => {
        state.loadingAllowance = false;
        state.error = action.payload;
      })

      .addCase(getFindOnePlanUser.pending, (state, action: any) => {
        state.isLoading = true;
        state.dataFindOnePlanUser = [];
        state.error = null;
      })
      .addCase(getFindOnePlanUser.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.dataFindOnePlanUser = action.payload;
        state.error = null;
      })
      .addCase(getFindOnePlanUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      })

      .addCase(getAllUsersHaveAdvance.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllUsersHaveAdvance.fulfilled, (state, action: any) => {
        state.dataUsersHaveAdvance = action.payload?.data;
      })
      .addCase(getAllUsersHaveAdvance.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      //bonus

      .addCase(getAllBonusUser.pending, (state, action: any) => {
        state.loadingBonus = true;
        state.error = null;
      })
      .addCase(getAllBonusUser.fulfilled, (state, action: any) => {
        state.loadingBonus = false;
        state.dataAssignBonus = action.payload?.data;
      })
      .addCase(getAllBonusUser.rejected, (state, action: any) => {
        state.loadingBonus = false;
        state.error = action.payload;
      })
      .addCase(getAllBonusListForUser.pending, (state, action: any) => {
        state.loadingBonus = true;
        state.error = null;
      })
      .addCase(getAllBonusListForUser.fulfilled, (state, action: any) => {
        state.loadingBonus = false;
        state.dataListBonus = action.payload?.data;
      })
      .addCase(getAllBonusListForUser.rejected, (state, action: any) => {
        state.loadingBonus = false;
        state.error = action.payload;
      })
      .addCase(getAllUsersHaveBonus.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllUsersHaveBonus.fulfilled, (state, action: any) => {
        state.dataUsersHaveBonus = action.payload?.data;
      })
      .addCase(getAllUsersHaveBonus.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(getAllDependentsUser.pending, (state, action: any) => {
        state.loadingDependents = true;
        state.error = null;
      })
      .addCase(getAllDependentsUser.fulfilled, (state, action: any) => {
        state.loadingDependents = false;
        state.dataAssignDependents = action.payload?.data;
      })
      .addCase(getAllDependentsUser.rejected, (state, action: any) => {
        state.loadingDependents = false;
        state.error = action.payload;
      })
      .addCase(getAllUsersHaveDependents.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllUsersHaveDependents.fulfilled, (state, action: any) => {
        state.dataUsersHaveDependents = action.payload?.data;
      })
      .addCase(getAllUsersHaveDependents.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(getContractUser.pending, (state, action: any) => {
        state.loadingContract = true;
        state.error = null;
      })
      .addCase(getContractUser.fulfilled, (state, action: any) => {
        state.loadingContract = false;
        state.dataUserContract = action.payload?.data;
      })
      .addCase(getContractUser.rejected, (state, action: any) => {
        state.loadingContract = false;
        state.error = action.payload;
      })

      //adjustment
      .addCase(getAllAdjustmentUser.pending, (state, action: any) => {
        state.loadingAdjustment = true;
        state.error = null;
      })
      .addCase(getAllAdjustmentUser.fulfilled, (state, action: any) => {
        state.loadingAdjustment = false;
        state.dataAssignAdjustment = action.payload?.data;
      })
      .addCase(getAllAdjustmentUser.rejected, (state, action: any) => {
        state.loadingAdjustment = false;
        state.error = action.payload;
      })

      // .addCase(getAllAdjustmentListForUser.pending, (state, action: any) => {
      //   state.loadingAdjustment = true;
      //   state.error = null;
      // })
      // .addCase(getAllAdjustmentListForUser.fulfilled, (state, action: any) => {
      //   state.loadingAdjustment = false;
      //   state.dataListAdjustment = action.payload?.data;
      // })
      // .addCase(getAllAdjustmentListForUser.rejected, (state, action: any) => {
      //   state.loadingAdjustment = false;
      //   state.error = action.payload;
      // })

      .addCase(getAllUsersHaveAdjustment.pending, (state, action: any) => {
        state.loadingAdjustment = true;
        state.error = null;
      })
      .addCase(getAllUsersHaveAdjustment.fulfilled, (state, action: any) => {
        state.loadingAdjustment = false;
        state.dataUsersHaveAdjustment = action.payload?.data;
      })
      .addCase(getAllUsersHaveAdjustment.rejected, (state, action: any) => {
        state.loadingAdjustment = false;
        state.error = action.payload;
      })
      .addCase(getManagerList.pending, (state, action: any) => {
        state.listManager = null;
      })
      .addCase(getManagerList.fulfilled, (state, action: any) => {
        state.listManager = action.payload?.data;
      })
      .addCase(getManagerList.rejected, (state, action: any) => {
        state.listManager = null;
      });
  },
});

export const selectEmployee = (state: RootState) => state.employee;

export const selectEmployeeList = (state: RootState) => state.employee.employeeList;

export const selectEmployeeFilterList = (state: RootState) => state.employee.employeeFilterList;

export const selectEmployeeTotalResult = (state: RootState) => state.employee.total;

export const selectEmployeeTotalSearch = (state: RootState) => state.employee.totalSearch;

export const selectIsGettingEmployee = (state: RootState) => state.employee.isGettingEmployees;

export const selectSizePageAndCurrentPage = createSelector(
  selectEmployee,
  (employee: { sizePage: number; currentPage: number }) => ({
    sizePage: employee.sizePage,
    currentPage: employee.currentPage,
  }),
);

export const { reducer, actions } = employee;

export const {
  setEmployeeFilterList,
  setSizePageOrCurrentPage,
  setCheckRow,
  setCheckExpand,
  setCheckAllStatus,
} = actions;

export default reducer;
